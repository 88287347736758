/*!
 * Bootstrap v4.5.0 (https://getbootstrap.com/)
 * Copyright 2011-2020 The Bootstrap Authors
 * Copyright 2011-2020 Twitter, Inc.
 * Licensed under MIT (https://github.com/twbs/bootstrap/blob/master/LICENSE)
 */
:root {
  --blue: #007bff;
  --indigo: #6610f2;
  --purple: #6f42c1;
  --pink: #e83e8c;
  --red: #dc3545;
  --orange: #fd7e14;
  --yellow: #ffc107;
  --green: #28a745;
  --teal: #20c997;
  --cyan: #17a2b8;
  --white: #fff;
  --gray: #6c757d;
  --gray-dark: #343a40;
  --primary: #007bff;
  --secondary: #6c757d;
  --success: #28a745;
  --info: #17a2b8;
  --warning: #ffc107;
  --danger: #dc3545;
  --light: #f8f9fa;
  --dark: #343a40;
  --breakpoint-xs: 0;
  --breakpoint-sm: 576px;
  --breakpoint-md: 768px;
  --breakpoint-lg: 992px;
  --breakpoint-xl: 1200px;
  --font-family-sans-serif: -apple-system, BlinkMacSystemFont, "Segoe UI", Roboto, "Helvetica Neue", Arial, "Noto Sans", sans-serif, "Apple Color Emoji", "Segoe UI Emoji", "Segoe UI Symbol", "Noto Color Emoji";
  --font-family-monospace: SFMono-Regular, Menlo, Monaco, Consolas, "Liberation Mono", "Courier New", monospace; }

*,
*::before,
*::after {
  box-sizing: border-box; }

html {
  font-family: sans-serif;
  line-height: 1.15;
  -webkit-text-size-adjust: 100%;
  -webkit-tap-highlight-color: rgba(0, 0, 0, 0); }

article, aside, figcaption, figure, footer, header, hgroup, main, nav, section {
  display: block; }

body {
  margin: 0;
  font-family: -apple-system, BlinkMacSystemFont, "Segoe UI", Roboto, "Helvetica Neue", Arial, "Noto Sans", sans-serif, "Apple Color Emoji", "Segoe UI Emoji", "Segoe UI Symbol", "Noto Color Emoji";
  font-size: 1rem;
  font-weight: 400;
  line-height: 1.5;
  color: #212529;
  text-align: left;
  background-color: #fff; }

[tabindex="-1"]:focus:not(:focus-visible) {
  outline: 0 !important; }

hr {
  box-sizing: content-box;
  height: 0;
  overflow: visible; }

h1, h2, h3, h4, h5, h6 {
  margin-top: 0;
  margin-bottom: 0.5rem; }

p {
  margin-top: 0;
  margin-bottom: 1rem; }

abbr[title],
abbr[data-original-title] {
  text-decoration: underline;
  text-decoration: underline dotted;
  cursor: help;
  border-bottom: 0;
  text-decoration-skip-ink: none; }

address {
  margin-bottom: 1rem;
  font-style: normal;
  line-height: inherit; }

ol,
ul,
dl {
  margin-top: 0;
  margin-bottom: 1rem; }

ol ol,
ul ul,
ol ul,
ul ol {
  margin-bottom: 0; }

dt {
  font-weight: 700; }

dd {
  margin-bottom: .5rem;
  margin-left: 0; }

blockquote {
  margin: 0 0 1rem; }

b,
strong {
  font-weight: bolder; }

small {
  font-size: 80%; }

sub,
sup {
  position: relative;
  font-size: 75%;
  line-height: 0;
  vertical-align: baseline; }

sub {
  bottom: -.25em; }

sup {
  top: -.5em; }

a {
  color: #007bff;
  text-decoration: none;
  background-color: transparent; }
  a:hover {
    color: #0056b3;
    text-decoration: underline; }

a:not([href]) {
  color: inherit;
  text-decoration: none; }
  a:not([href]):hover {
    color: inherit;
    text-decoration: none; }

pre,
code,
kbd,
samp {
  font-family: SFMono-Regular, Menlo, Monaco, Consolas, "Liberation Mono", "Courier New", monospace;
  font-size: 1em; }

pre {
  margin-top: 0;
  margin-bottom: 1rem;
  overflow: auto;
  -ms-overflow-style: scrollbar; }

figure {
  margin: 0 0 1rem; }

img {
  vertical-align: middle;
  border-style: none; }

svg {
  overflow: hidden;
  vertical-align: middle; }

table {
  border-collapse: collapse; }

caption {
  padding-top: 0.75rem;
  padding-bottom: 0.75rem;
  color: #6c757d;
  text-align: left;
  caption-side: bottom; }

th {
  text-align: inherit; }

label {
  display: inline-block;
  margin-bottom: 0.5rem; }

button {
  border-radius: 0; }

button:focus {
  outline: 1px dotted;
  outline: 5px auto -webkit-focus-ring-color; }

input,
button,
select,
optgroup,
textarea {
  margin: 0;
  font-family: inherit;
  font-size: inherit;
  line-height: inherit; }

button,
input {
  overflow: visible; }

button,
select {
  text-transform: none; }

[role="button"] {
  cursor: pointer; }

select {
  word-wrap: normal; }

button,
[type="button"],
[type="reset"],
[type="submit"] {
  -webkit-appearance: button; }

button:not(:disabled),
[type="button"]:not(:disabled),
[type="reset"]:not(:disabled),
[type="submit"]:not(:disabled) {
  cursor: pointer; }

button::-moz-focus-inner,
[type="button"]::-moz-focus-inner,
[type="reset"]::-moz-focus-inner,
[type="submit"]::-moz-focus-inner {
  padding: 0;
  border-style: none; }

input[type="radio"],
input[type="checkbox"] {
  box-sizing: border-box;
  padding: 0; }

textarea {
  overflow: auto;
  resize: vertical; }

fieldset {
  min-width: 0;
  padding: 0;
  margin: 0;
  border: 0; }

legend {
  display: block;
  width: 100%;
  max-width: 100%;
  padding: 0;
  margin-bottom: .5rem;
  font-size: 1.5rem;
  line-height: inherit;
  color: inherit;
  white-space: normal; }

progress {
  vertical-align: baseline; }

[type="number"]::-webkit-inner-spin-button,
[type="number"]::-webkit-outer-spin-button {
  height: auto; }

[type="search"] {
  outline-offset: -2px;
  -webkit-appearance: none; }

[type="search"]::-webkit-search-decoration {
  -webkit-appearance: none; }

::-webkit-file-upload-button {
  font: inherit;
  -webkit-appearance: button; }

output {
  display: inline-block; }

summary {
  display: list-item;
  cursor: pointer; }

template {
  display: none; }

[hidden] {
  display: none !important; }

h1, h2, h3, h4, h5, h6,
.h1, .h2, .h3, .h4, .h5, .h6 {
  margin-bottom: 0.5rem;
  font-weight: 500;
  line-height: 1.2; }

h1, .h1 {
  font-size: 2.5rem; }

h2, .h2 {
  font-size: 2rem; }

h3, .h3 {
  font-size: 1.75rem; }

h4, .h4 {
  font-size: 1.5rem; }

h5, .h5 {
  font-size: 1.25rem; }

h6, .h6 {
  font-size: 1rem; }

.lead {
  font-size: 1.25rem;
  font-weight: 300; }

.display-1 {
  font-size: 6rem;
  font-weight: 300;
  line-height: 1.2; }

.display-2 {
  font-size: 5.5rem;
  font-weight: 300;
  line-height: 1.2; }

.display-3 {
  font-size: 4.5rem;
  font-weight: 300;
  line-height: 1.2; }

.display-4 {
  font-size: 3.5rem;
  font-weight: 300;
  line-height: 1.2; }

hr {
  margin-top: 1rem;
  margin-bottom: 1rem;
  border: 0;
  border-top: 1px solid rgba(0, 0, 0, 0.1); }

small,
.small {
  font-size: 80%;
  font-weight: 400; }

mark,
.mark {
  padding: 0.2em;
  background-color: #fcf8e3; }

.list-unstyled {
  padding-left: 0;
  list-style: none; }

.list-inline {
  padding-left: 0;
  list-style: none; }

.list-inline-item {
  display: inline-block; }
  .list-inline-item:not(:last-child) {
    margin-right: 0.5rem; }

.initialism {
  font-size: 90%;
  text-transform: uppercase; }

.blockquote {
  margin-bottom: 1rem;
  font-size: 1.25rem; }

.blockquote-footer {
  display: block;
  font-size: 80%;
  color: #6c757d; }
  .blockquote-footer::before {
    content: "\2014\00A0"; }

.img-fluid {
  max-width: 100%;
  height: auto; }

.img-thumbnail {
  padding: 0.25rem;
  background-color: #fff;
  border: 1px solid #dee2e6;
  border-radius: 0.25rem;
  max-width: 100%;
  height: auto; }

.figure {
  display: inline-block; }

.figure-img {
  margin-bottom: 0.5rem;
  line-height: 1; }

.figure-caption {
  font-size: 90%;
  color: #6c757d; }

code {
  font-size: 87.5%;
  color: #e83e8c;
  word-wrap: break-word; }
  a > code {
    color: inherit; }

kbd {
  padding: 0.2rem 0.4rem;
  font-size: 87.5%;
  color: #fff;
  background-color: #212529;
  border-radius: 0.2rem; }
  kbd kbd {
    padding: 0;
    font-size: 100%;
    font-weight: 700; }

pre {
  display: block;
  font-size: 87.5%;
  color: #212529; }
  pre code {
    font-size: inherit;
    color: inherit;
    word-break: normal; }

.pre-scrollable {
  max-height: 340px;
  overflow-y: scroll; }

.container {
  width: 100%;
  padding-right: 8px;
  padding-left: 8px;
  margin-right: auto;
  margin-left: auto; }
  @media (min-width: 576px) {
    .container {
      max-width: 540px; } }
  @media (min-width: 768px) {
    .container {
      max-width: 720px; } }
  @media (min-width: 992px) {
    .container {
      max-width: 960px; } }
  @media (min-width: 1200px) {
    .container {
      max-width: 1140px; } }

.container-fluid, .container-sm, .container-md, .container-lg, .container-xl {
  width: 100%;
  padding-right: 8px;
  padding-left: 8px;
  margin-right: auto;
  margin-left: auto; }

@media (min-width: 576px) {
  .container, .container-sm {
    max-width: 540px; } }

@media (min-width: 768px) {
  .container, .container-sm, .container-md {
    max-width: 720px; } }

@media (min-width: 992px) {
  .container, .container-sm, .container-md, .container-lg {
    max-width: 960px; } }

@media (min-width: 1200px) {
  .container, .container-sm, .container-md, .container-lg, .container-xl {
    max-width: 1140px; } }

.row {
  display: flex;
  flex-wrap: wrap;
  margin-right: -8px;
  margin-left: -8px; }

.no-gutters {
  margin-right: 0;
  margin-left: 0; }
  .no-gutters > .col,
  .no-gutters > [class*="col-"] {
    padding-right: 0;
    padding-left: 0; }

.col-1, .col-2, .col-3, .col-4, .col-5, .col-6, .col-7, .col-8, .col-9, .col-10, .col-11, .col-12, .col,
.col-auto, .col-sm-1, .col-sm-2, .col-sm-3, .col-sm-4, .col-sm-5, .col-sm-6, .col-sm-7, .col-sm-8, .col-sm-9, .col-sm-10, .col-sm-11, .col-sm-12, .col-sm,
.col-sm-auto, .col-md-1, .col-md-2, .col-md-3, .col-md-4, .col-md-5, .col-md-6, .col-md-7, .col-md-8, .col-md-9, .col-md-10, .col-md-11, .col-md-12, .col-md,
.col-md-auto, .col-lg-1, .col-lg-2, .col-lg-3, .col-lg-4, .col-lg-5, .col-lg-6, .col-lg-7, .col-lg-8, .col-lg-9, .col-lg-10, .col-lg-11, .col-lg-12, .col-lg,
.col-lg-auto, .col-xl-1, .col-xl-2, .col-xl-3, .col-xl-4, .col-xl-5, .col-xl-6, .col-xl-7, .col-xl-8, .col-xl-9, .col-xl-10, .col-xl-11, .col-xl-12, .col-xl,
.col-xl-auto {
  position: relative;
  width: 100%;
  padding-right: 8px;
  padding-left: 8px; }

.col {
  flex-basis: 0;
  flex-grow: 1;
  min-width: 0;
  max-width: 100%; }

.row-cols-1 > * {
  flex: 0 0 100%;
  max-width: 100%; }

.row-cols-2 > * {
  flex: 0 0 50%;
  max-width: 50%; }

.row-cols-3 > * {
  flex: 0 0 33.33333%;
  max-width: 33.33333%; }

.row-cols-4 > * {
  flex: 0 0 25%;
  max-width: 25%; }

.row-cols-5 > * {
  flex: 0 0 20%;
  max-width: 20%; }

.row-cols-6 > * {
  flex: 0 0 16.66667%;
  max-width: 16.66667%; }

.col-auto {
  flex: 0 0 auto;
  width: auto;
  max-width: 100%; }

.col-1 {
  flex: 0 0 8.33333%;
  max-width: 8.33333%; }

.col-2 {
  flex: 0 0 16.66667%;
  max-width: 16.66667%; }

.col-3 {
  flex: 0 0 25%;
  max-width: 25%; }

.col-4 {
  flex: 0 0 33.33333%;
  max-width: 33.33333%; }

.col-5 {
  flex: 0 0 41.66667%;
  max-width: 41.66667%; }

.col-6 {
  flex: 0 0 50%;
  max-width: 50%; }

.col-7 {
  flex: 0 0 58.33333%;
  max-width: 58.33333%; }

.col-8 {
  flex: 0 0 66.66667%;
  max-width: 66.66667%; }

.col-9 {
  flex: 0 0 75%;
  max-width: 75%; }

.col-10 {
  flex: 0 0 83.33333%;
  max-width: 83.33333%; }

.col-11 {
  flex: 0 0 91.66667%;
  max-width: 91.66667%; }

.col-12 {
  flex: 0 0 100%;
  max-width: 100%; }

.order-first {
  order: -1; }

.order-last {
  order: 13; }

.order-0 {
  order: 0; }

.order-1 {
  order: 1; }

.order-2 {
  order: 2; }

.order-3 {
  order: 3; }

.order-4 {
  order: 4; }

.order-5 {
  order: 5; }

.order-6 {
  order: 6; }

.order-7 {
  order: 7; }

.order-8 {
  order: 8; }

.order-9 {
  order: 9; }

.order-10 {
  order: 10; }

.order-11 {
  order: 11; }

.order-12 {
  order: 12; }

.offset-1 {
  margin-left: 8.33333%; }

.offset-2 {
  margin-left: 16.66667%; }

.offset-3 {
  margin-left: 25%; }

.offset-4 {
  margin-left: 33.33333%; }

.offset-5 {
  margin-left: 41.66667%; }

.offset-6 {
  margin-left: 50%; }

.offset-7 {
  margin-left: 58.33333%; }

.offset-8 {
  margin-left: 66.66667%; }

.offset-9 {
  margin-left: 75%; }

.offset-10 {
  margin-left: 83.33333%; }

.offset-11 {
  margin-left: 91.66667%; }

@media (min-width: 576px) {
  .col-sm {
    flex-basis: 0;
    flex-grow: 1;
    min-width: 0;
    max-width: 100%; }
  .row-cols-sm-1 > * {
    flex: 0 0 100%;
    max-width: 100%; }
  .row-cols-sm-2 > * {
    flex: 0 0 50%;
    max-width: 50%; }
  .row-cols-sm-3 > * {
    flex: 0 0 33.33333%;
    max-width: 33.33333%; }
  .row-cols-sm-4 > * {
    flex: 0 0 25%;
    max-width: 25%; }
  .row-cols-sm-5 > * {
    flex: 0 0 20%;
    max-width: 20%; }
  .row-cols-sm-6 > * {
    flex: 0 0 16.66667%;
    max-width: 16.66667%; }
  .col-sm-auto {
    flex: 0 0 auto;
    width: auto;
    max-width: 100%; }
  .col-sm-1 {
    flex: 0 0 8.33333%;
    max-width: 8.33333%; }
  .col-sm-2 {
    flex: 0 0 16.66667%;
    max-width: 16.66667%; }
  .col-sm-3 {
    flex: 0 0 25%;
    max-width: 25%; }
  .col-sm-4 {
    flex: 0 0 33.33333%;
    max-width: 33.33333%; }
  .col-sm-5 {
    flex: 0 0 41.66667%;
    max-width: 41.66667%; }
  .col-sm-6 {
    flex: 0 0 50%;
    max-width: 50%; }
  .col-sm-7 {
    flex: 0 0 58.33333%;
    max-width: 58.33333%; }
  .col-sm-8 {
    flex: 0 0 66.66667%;
    max-width: 66.66667%; }
  .col-sm-9 {
    flex: 0 0 75%;
    max-width: 75%; }
  .col-sm-10 {
    flex: 0 0 83.33333%;
    max-width: 83.33333%; }
  .col-sm-11 {
    flex: 0 0 91.66667%;
    max-width: 91.66667%; }
  .col-sm-12 {
    flex: 0 0 100%;
    max-width: 100%; }
  .order-sm-first {
    order: -1; }
  .order-sm-last {
    order: 13; }
  .order-sm-0 {
    order: 0; }
  .order-sm-1 {
    order: 1; }
  .order-sm-2 {
    order: 2; }
  .order-sm-3 {
    order: 3; }
  .order-sm-4 {
    order: 4; }
  .order-sm-5 {
    order: 5; }
  .order-sm-6 {
    order: 6; }
  .order-sm-7 {
    order: 7; }
  .order-sm-8 {
    order: 8; }
  .order-sm-9 {
    order: 9; }
  .order-sm-10 {
    order: 10; }
  .order-sm-11 {
    order: 11; }
  .order-sm-12 {
    order: 12; }
  .offset-sm-0 {
    margin-left: 0; }
  .offset-sm-1 {
    margin-left: 8.33333%; }
  .offset-sm-2 {
    margin-left: 16.66667%; }
  .offset-sm-3 {
    margin-left: 25%; }
  .offset-sm-4 {
    margin-left: 33.33333%; }
  .offset-sm-5 {
    margin-left: 41.66667%; }
  .offset-sm-6 {
    margin-left: 50%; }
  .offset-sm-7 {
    margin-left: 58.33333%; }
  .offset-sm-8 {
    margin-left: 66.66667%; }
  .offset-sm-9 {
    margin-left: 75%; }
  .offset-sm-10 {
    margin-left: 83.33333%; }
  .offset-sm-11 {
    margin-left: 91.66667%; } }

@media (min-width: 768px) {
  .col-md {
    flex-basis: 0;
    flex-grow: 1;
    min-width: 0;
    max-width: 100%; }
  .row-cols-md-1 > * {
    flex: 0 0 100%;
    max-width: 100%; }
  .row-cols-md-2 > * {
    flex: 0 0 50%;
    max-width: 50%; }
  .row-cols-md-3 > * {
    flex: 0 0 33.33333%;
    max-width: 33.33333%; }
  .row-cols-md-4 > * {
    flex: 0 0 25%;
    max-width: 25%; }
  .row-cols-md-5 > * {
    flex: 0 0 20%;
    max-width: 20%; }
  .row-cols-md-6 > * {
    flex: 0 0 16.66667%;
    max-width: 16.66667%; }
  .col-md-auto {
    flex: 0 0 auto;
    width: auto;
    max-width: 100%; }
  .col-md-1 {
    flex: 0 0 8.33333%;
    max-width: 8.33333%; }
  .col-md-2 {
    flex: 0 0 16.66667%;
    max-width: 16.66667%; }
  .col-md-3 {
    flex: 0 0 25%;
    max-width: 25%; }
  .col-md-4 {
    flex: 0 0 33.33333%;
    max-width: 33.33333%; }
  .col-md-5 {
    flex: 0 0 41.66667%;
    max-width: 41.66667%; }
  .col-md-6 {
    flex: 0 0 50%;
    max-width: 50%; }
  .col-md-7 {
    flex: 0 0 58.33333%;
    max-width: 58.33333%; }
  .col-md-8 {
    flex: 0 0 66.66667%;
    max-width: 66.66667%; }
  .col-md-9 {
    flex: 0 0 75%;
    max-width: 75%; }
  .col-md-10 {
    flex: 0 0 83.33333%;
    max-width: 83.33333%; }
  .col-md-11 {
    flex: 0 0 91.66667%;
    max-width: 91.66667%; }
  .col-md-12 {
    flex: 0 0 100%;
    max-width: 100%; }
  .order-md-first {
    order: -1; }
  .order-md-last {
    order: 13; }
  .order-md-0 {
    order: 0; }
  .order-md-1 {
    order: 1; }
  .order-md-2 {
    order: 2; }
  .order-md-3 {
    order: 3; }
  .order-md-4 {
    order: 4; }
  .order-md-5 {
    order: 5; }
  .order-md-6 {
    order: 6; }
  .order-md-7 {
    order: 7; }
  .order-md-8 {
    order: 8; }
  .order-md-9 {
    order: 9; }
  .order-md-10 {
    order: 10; }
  .order-md-11 {
    order: 11; }
  .order-md-12 {
    order: 12; }
  .offset-md-0 {
    margin-left: 0; }
  .offset-md-1 {
    margin-left: 8.33333%; }
  .offset-md-2 {
    margin-left: 16.66667%; }
  .offset-md-3 {
    margin-left: 25%; }
  .offset-md-4 {
    margin-left: 33.33333%; }
  .offset-md-5 {
    margin-left: 41.66667%; }
  .offset-md-6 {
    margin-left: 50%; }
  .offset-md-7 {
    margin-left: 58.33333%; }
  .offset-md-8 {
    margin-left: 66.66667%; }
  .offset-md-9 {
    margin-left: 75%; }
  .offset-md-10 {
    margin-left: 83.33333%; }
  .offset-md-11 {
    margin-left: 91.66667%; } }

@media (min-width: 992px) {
  .col-lg {
    flex-basis: 0;
    flex-grow: 1;
    min-width: 0;
    max-width: 100%; }
  .row-cols-lg-1 > * {
    flex: 0 0 100%;
    max-width: 100%; }
  .row-cols-lg-2 > * {
    flex: 0 0 50%;
    max-width: 50%; }
  .row-cols-lg-3 > * {
    flex: 0 0 33.33333%;
    max-width: 33.33333%; }
  .row-cols-lg-4 > * {
    flex: 0 0 25%;
    max-width: 25%; }
  .row-cols-lg-5 > * {
    flex: 0 0 20%;
    max-width: 20%; }
  .row-cols-lg-6 > * {
    flex: 0 0 16.66667%;
    max-width: 16.66667%; }
  .col-lg-auto {
    flex: 0 0 auto;
    width: auto;
    max-width: 100%; }
  .col-lg-1 {
    flex: 0 0 8.33333%;
    max-width: 8.33333%; }
  .col-lg-2 {
    flex: 0 0 16.66667%;
    max-width: 16.66667%; }
  .col-lg-3 {
    flex: 0 0 25%;
    max-width: 25%; }
  .col-lg-4 {
    flex: 0 0 33.33333%;
    max-width: 33.33333%; }
  .col-lg-5 {
    flex: 0 0 41.66667%;
    max-width: 41.66667%; }
  .col-lg-6 {
    flex: 0 0 50%;
    max-width: 50%; }
  .col-lg-7 {
    flex: 0 0 58.33333%;
    max-width: 58.33333%; }
  .col-lg-8 {
    flex: 0 0 66.66667%;
    max-width: 66.66667%; }
  .col-lg-9 {
    flex: 0 0 75%;
    max-width: 75%; }
  .col-lg-10 {
    flex: 0 0 83.33333%;
    max-width: 83.33333%; }
  .col-lg-11 {
    flex: 0 0 91.66667%;
    max-width: 91.66667%; }
  .col-lg-12 {
    flex: 0 0 100%;
    max-width: 100%; }
  .order-lg-first {
    order: -1; }
  .order-lg-last {
    order: 13; }
  .order-lg-0 {
    order: 0; }
  .order-lg-1 {
    order: 1; }
  .order-lg-2 {
    order: 2; }
  .order-lg-3 {
    order: 3; }
  .order-lg-4 {
    order: 4; }
  .order-lg-5 {
    order: 5; }
  .order-lg-6 {
    order: 6; }
  .order-lg-7 {
    order: 7; }
  .order-lg-8 {
    order: 8; }
  .order-lg-9 {
    order: 9; }
  .order-lg-10 {
    order: 10; }
  .order-lg-11 {
    order: 11; }
  .order-lg-12 {
    order: 12; }
  .offset-lg-0 {
    margin-left: 0; }
  .offset-lg-1 {
    margin-left: 8.33333%; }
  .offset-lg-2 {
    margin-left: 16.66667%; }
  .offset-lg-3 {
    margin-left: 25%; }
  .offset-lg-4 {
    margin-left: 33.33333%; }
  .offset-lg-5 {
    margin-left: 41.66667%; }
  .offset-lg-6 {
    margin-left: 50%; }
  .offset-lg-7 {
    margin-left: 58.33333%; }
  .offset-lg-8 {
    margin-left: 66.66667%; }
  .offset-lg-9 {
    margin-left: 75%; }
  .offset-lg-10 {
    margin-left: 83.33333%; }
  .offset-lg-11 {
    margin-left: 91.66667%; } }

@media (min-width: 1200px) {
  .col-xl {
    flex-basis: 0;
    flex-grow: 1;
    min-width: 0;
    max-width: 100%; }
  .row-cols-xl-1 > * {
    flex: 0 0 100%;
    max-width: 100%; }
  .row-cols-xl-2 > * {
    flex: 0 0 50%;
    max-width: 50%; }
  .row-cols-xl-3 > * {
    flex: 0 0 33.33333%;
    max-width: 33.33333%; }
  .row-cols-xl-4 > * {
    flex: 0 0 25%;
    max-width: 25%; }
  .row-cols-xl-5 > * {
    flex: 0 0 20%;
    max-width: 20%; }
  .row-cols-xl-6 > * {
    flex: 0 0 16.66667%;
    max-width: 16.66667%; }
  .col-xl-auto {
    flex: 0 0 auto;
    width: auto;
    max-width: 100%; }
  .col-xl-1 {
    flex: 0 0 8.33333%;
    max-width: 8.33333%; }
  .col-xl-2 {
    flex: 0 0 16.66667%;
    max-width: 16.66667%; }
  .col-xl-3 {
    flex: 0 0 25%;
    max-width: 25%; }
  .col-xl-4 {
    flex: 0 0 33.33333%;
    max-width: 33.33333%; }
  .col-xl-5 {
    flex: 0 0 41.66667%;
    max-width: 41.66667%; }
  .col-xl-6 {
    flex: 0 0 50%;
    max-width: 50%; }
  .col-xl-7 {
    flex: 0 0 58.33333%;
    max-width: 58.33333%; }
  .col-xl-8 {
    flex: 0 0 66.66667%;
    max-width: 66.66667%; }
  .col-xl-9 {
    flex: 0 0 75%;
    max-width: 75%; }
  .col-xl-10 {
    flex: 0 0 83.33333%;
    max-width: 83.33333%; }
  .col-xl-11 {
    flex: 0 0 91.66667%;
    max-width: 91.66667%; }
  .col-xl-12 {
    flex: 0 0 100%;
    max-width: 100%; }
  .order-xl-first {
    order: -1; }
  .order-xl-last {
    order: 13; }
  .order-xl-0 {
    order: 0; }
  .order-xl-1 {
    order: 1; }
  .order-xl-2 {
    order: 2; }
  .order-xl-3 {
    order: 3; }
  .order-xl-4 {
    order: 4; }
  .order-xl-5 {
    order: 5; }
  .order-xl-6 {
    order: 6; }
  .order-xl-7 {
    order: 7; }
  .order-xl-8 {
    order: 8; }
  .order-xl-9 {
    order: 9; }
  .order-xl-10 {
    order: 10; }
  .order-xl-11 {
    order: 11; }
  .order-xl-12 {
    order: 12; }
  .offset-xl-0 {
    margin-left: 0; }
  .offset-xl-1 {
    margin-left: 8.33333%; }
  .offset-xl-2 {
    margin-left: 16.66667%; }
  .offset-xl-3 {
    margin-left: 25%; }
  .offset-xl-4 {
    margin-left: 33.33333%; }
  .offset-xl-5 {
    margin-left: 41.66667%; }
  .offset-xl-6 {
    margin-left: 50%; }
  .offset-xl-7 {
    margin-left: 58.33333%; }
  .offset-xl-8 {
    margin-left: 66.66667%; }
  .offset-xl-9 {
    margin-left: 75%; }
  .offset-xl-10 {
    margin-left: 83.33333%; }
  .offset-xl-11 {
    margin-left: 91.66667%; } }

@keyframes spinner-border {
  to {
    transform: rotate(360deg); } }

.spinner-border {
  display: inline-block;
  width: 2rem;
  height: 2rem;
  vertical-align: text-bottom;
  border: 0.25em solid currentColor;
  border-right-color: transparent;
  border-radius: 50%;
  animation: spinner-border .75s linear infinite; }

.spinner-border-sm {
  width: 1rem;
  height: 1rem;
  border-width: 0.2em; }

@keyframes spinner-grow {
  0% {
    transform: scale(0); }
  50% {
    opacity: 1;
    transform: none; } }

.spinner-grow {
  display: inline-block;
  width: 2rem;
  height: 2rem;
  vertical-align: text-bottom;
  background-color: currentColor;
  border-radius: 50%;
  opacity: 0;
  animation: spinner-grow .75s linear infinite; }

.spinner-grow-sm {
  width: 1rem;
  height: 1rem; }

.align-baseline {
  vertical-align: baseline !important; }

.align-top {
  vertical-align: top !important; }

.align-middle {
  vertical-align: middle !important; }

.align-bottom {
  vertical-align: bottom !important; }

.align-text-bottom {
  vertical-align: text-bottom !important; }

.align-text-top {
  vertical-align: text-top !important; }

.bg-primary {
  background-color: #007bff !important; }

a.bg-primary:hover, a.bg-primary:focus,
button.bg-primary:hover,
button.bg-primary:focus {
  background-color: #0062cc !important; }

.bg-secondary {
  background-color: #6c757d !important; }

a.bg-secondary:hover, a.bg-secondary:focus,
button.bg-secondary:hover,
button.bg-secondary:focus {
  background-color: #545b62 !important; }

.bg-success {
  background-color: #28a745 !important; }

a.bg-success:hover, a.bg-success:focus,
button.bg-success:hover,
button.bg-success:focus {
  background-color: #1e7e34 !important; }

.bg-info {
  background-color: #17a2b8 !important; }

a.bg-info:hover, a.bg-info:focus,
button.bg-info:hover,
button.bg-info:focus {
  background-color: #117a8b !important; }

.bg-warning {
  background-color: #ffc107 !important; }

a.bg-warning:hover, a.bg-warning:focus,
button.bg-warning:hover,
button.bg-warning:focus {
  background-color: #d39e00 !important; }

.bg-danger {
  background-color: #dc3545 !important; }

a.bg-danger:hover, a.bg-danger:focus,
button.bg-danger:hover,
button.bg-danger:focus {
  background-color: #bd2130 !important; }

.bg-light {
  background-color: #f8f9fa !important; }

a.bg-light:hover, a.bg-light:focus,
button.bg-light:hover,
button.bg-light:focus {
  background-color: #dae0e5 !important; }

.bg-dark {
  background-color: #343a40 !important; }

a.bg-dark:hover, a.bg-dark:focus,
button.bg-dark:hover,
button.bg-dark:focus {
  background-color: #1d2124 !important; }

.bg-white {
  background-color: #fff !important; }

.bg-transparent {
  background-color: transparent !important; }

.border {
  border: 1px solid #dee2e6 !important; }

.border-top {
  border-top: 1px solid #dee2e6 !important; }

.border-right {
  border-right: 1px solid #dee2e6 !important; }

.border-bottom {
  border-bottom: 1px solid #dee2e6 !important; }

.border-left {
  border-left: 1px solid #dee2e6 !important; }

.border-0 {
  border: 0 !important; }

.border-top-0 {
  border-top: 0 !important; }

.border-right-0 {
  border-right: 0 !important; }

.border-bottom-0 {
  border-bottom: 0 !important; }

.border-left-0 {
  border-left: 0 !important; }

.border-primary {
  border-color: #007bff !important; }

.border-secondary {
  border-color: #6c757d !important; }

.border-success {
  border-color: #28a745 !important; }

.border-info {
  border-color: #17a2b8 !important; }

.border-warning {
  border-color: #ffc107 !important; }

.border-danger {
  border-color: #dc3545 !important; }

.border-light {
  border-color: #f8f9fa !important; }

.border-dark {
  border-color: #343a40 !important; }

.border-white {
  border-color: #fff !important; }

.rounded-sm {
  border-radius: 0.2rem !important; }

.rounded {
  border-radius: 0.25rem !important; }

.rounded-top {
  border-top-left-radius: 0.25rem !important;
  border-top-right-radius: 0.25rem !important; }

.rounded-right {
  border-top-right-radius: 0.25rem !important;
  border-bottom-right-radius: 0.25rem !important; }

.rounded-bottom {
  border-bottom-right-radius: 0.25rem !important;
  border-bottom-left-radius: 0.25rem !important; }

.rounded-left {
  border-top-left-radius: 0.25rem !important;
  border-bottom-left-radius: 0.25rem !important; }

.rounded-lg {
  border-radius: 0.3rem !important; }

.rounded-circle {
  border-radius: 50% !important; }

.rounded-pill {
  border-radius: 50rem !important; }

.rounded-0 {
  border-radius: 0 !important; }

.clearfix::after {
  display: block;
  clear: both;
  content: ""; }

.d-none {
  display: none !important; }

.d-inline {
  display: inline !important; }

.d-inline-block {
  display: inline-block !important; }

.d-block {
  display: block !important; }

.d-table {
  display: table !important; }

.d-table-row {
  display: table-row !important; }

.d-table-cell {
  display: table-cell !important; }

.d-flex {
  display: flex !important; }

.d-inline-flex {
  display: inline-flex !important; }

@media (min-width: 576px) {
  .d-sm-none {
    display: none !important; }
  .d-sm-inline {
    display: inline !important; }
  .d-sm-inline-block {
    display: inline-block !important; }
  .d-sm-block {
    display: block !important; }
  .d-sm-table {
    display: table !important; }
  .d-sm-table-row {
    display: table-row !important; }
  .d-sm-table-cell {
    display: table-cell !important; }
  .d-sm-flex {
    display: flex !important; }
  .d-sm-inline-flex {
    display: inline-flex !important; } }

@media (min-width: 768px) {
  .d-md-none {
    display: none !important; }
  .d-md-inline {
    display: inline !important; }
  .d-md-inline-block {
    display: inline-block !important; }
  .d-md-block {
    display: block !important; }
  .d-md-table {
    display: table !important; }
  .d-md-table-row {
    display: table-row !important; }
  .d-md-table-cell {
    display: table-cell !important; }
  .d-md-flex {
    display: flex !important; }
  .d-md-inline-flex {
    display: inline-flex !important; } }

@media (min-width: 992px) {
  .d-lg-none {
    display: none !important; }
  .d-lg-inline {
    display: inline !important; }
  .d-lg-inline-block {
    display: inline-block !important; }
  .d-lg-block {
    display: block !important; }
  .d-lg-table {
    display: table !important; }
  .d-lg-table-row {
    display: table-row !important; }
  .d-lg-table-cell {
    display: table-cell !important; }
  .d-lg-flex {
    display: flex !important; }
  .d-lg-inline-flex {
    display: inline-flex !important; } }

@media (min-width: 1200px) {
  .d-xl-none {
    display: none !important; }
  .d-xl-inline {
    display: inline !important; }
  .d-xl-inline-block {
    display: inline-block !important; }
  .d-xl-block {
    display: block !important; }
  .d-xl-table {
    display: table !important; }
  .d-xl-table-row {
    display: table-row !important; }
  .d-xl-table-cell {
    display: table-cell !important; }
  .d-xl-flex {
    display: flex !important; }
  .d-xl-inline-flex {
    display: inline-flex !important; } }

@media print {
  .d-print-none {
    display: none !important; }
  .d-print-inline {
    display: inline !important; }
  .d-print-inline-block {
    display: inline-block !important; }
  .d-print-block {
    display: block !important; }
  .d-print-table {
    display: table !important; }
  .d-print-table-row {
    display: table-row !important; }
  .d-print-table-cell {
    display: table-cell !important; }
  .d-print-flex {
    display: flex !important; }
  .d-print-inline-flex {
    display: inline-flex !important; } }

.embed-responsive {
  position: relative;
  display: block;
  width: 100%;
  padding: 0;
  overflow: hidden; }
  .embed-responsive::before {
    display: block;
    content: ""; }
  .embed-responsive .embed-responsive-item,
  .embed-responsive iframe,
  .embed-responsive embed,
  .embed-responsive object,
  .embed-responsive video {
    position: absolute;
    top: 0;
    bottom: 0;
    left: 0;
    width: 100%;
    height: 100%;
    border: 0; }

.embed-responsive-21by9::before {
  padding-top: 42.85714%; }

.embed-responsive-16by9::before {
  padding-top: 56.25%; }

.embed-responsive-4by3::before {
  padding-top: 75%; }

.embed-responsive-1by1::before {
  padding-top: 100%; }

.flex-row {
  flex-direction: row !important; }

.flex-column {
  flex-direction: column !important; }

.flex-row-reverse {
  flex-direction: row-reverse !important; }

.flex-column-reverse {
  flex-direction: column-reverse !important; }

.flex-wrap {
  flex-wrap: wrap !important; }

.flex-nowrap {
  flex-wrap: nowrap !important; }

.flex-wrap-reverse {
  flex-wrap: wrap-reverse !important; }

.flex-fill {
  flex: 1 1 auto !important; }

.flex-grow-0 {
  flex-grow: 0 !important; }

.flex-grow-1 {
  flex-grow: 1 !important; }

.flex-shrink-0 {
  flex-shrink: 0 !important; }

.flex-shrink-1 {
  flex-shrink: 1 !important; }

.justify-content-start {
  justify-content: flex-start !important; }

.justify-content-end {
  justify-content: flex-end !important; }

.justify-content-center {
  justify-content: center !important; }

.justify-content-between {
  justify-content: space-between !important; }

.justify-content-around {
  justify-content: space-around !important; }

.align-items-start {
  align-items: flex-start !important; }

.align-items-end {
  align-items: flex-end !important; }

.align-items-center {
  align-items: center !important; }

.align-items-baseline {
  align-items: baseline !important; }

.align-items-stretch {
  align-items: stretch !important; }

.align-content-start {
  align-content: flex-start !important; }

.align-content-end {
  align-content: flex-end !important; }

.align-content-center {
  align-content: center !important; }

.align-content-between {
  align-content: space-between !important; }

.align-content-around {
  align-content: space-around !important; }

.align-content-stretch {
  align-content: stretch !important; }

.align-self-auto {
  align-self: auto !important; }

.align-self-start {
  align-self: flex-start !important; }

.align-self-end {
  align-self: flex-end !important; }

.align-self-center {
  align-self: center !important; }

.align-self-baseline {
  align-self: baseline !important; }

.align-self-stretch {
  align-self: stretch !important; }

@media (min-width: 576px) {
  .flex-sm-row {
    flex-direction: row !important; }
  .flex-sm-column {
    flex-direction: column !important; }
  .flex-sm-row-reverse {
    flex-direction: row-reverse !important; }
  .flex-sm-column-reverse {
    flex-direction: column-reverse !important; }
  .flex-sm-wrap {
    flex-wrap: wrap !important; }
  .flex-sm-nowrap {
    flex-wrap: nowrap !important; }
  .flex-sm-wrap-reverse {
    flex-wrap: wrap-reverse !important; }
  .flex-sm-fill {
    flex: 1 1 auto !important; }
  .flex-sm-grow-0 {
    flex-grow: 0 !important; }
  .flex-sm-grow-1 {
    flex-grow: 1 !important; }
  .flex-sm-shrink-0 {
    flex-shrink: 0 !important; }
  .flex-sm-shrink-1 {
    flex-shrink: 1 !important; }
  .justify-content-sm-start {
    justify-content: flex-start !important; }
  .justify-content-sm-end {
    justify-content: flex-end !important; }
  .justify-content-sm-center {
    justify-content: center !important; }
  .justify-content-sm-between {
    justify-content: space-between !important; }
  .justify-content-sm-around {
    justify-content: space-around !important; }
  .align-items-sm-start {
    align-items: flex-start !important; }
  .align-items-sm-end {
    align-items: flex-end !important; }
  .align-items-sm-center {
    align-items: center !important; }
  .align-items-sm-baseline {
    align-items: baseline !important; }
  .align-items-sm-stretch {
    align-items: stretch !important; }
  .align-content-sm-start {
    align-content: flex-start !important; }
  .align-content-sm-end {
    align-content: flex-end !important; }
  .align-content-sm-center {
    align-content: center !important; }
  .align-content-sm-between {
    align-content: space-between !important; }
  .align-content-sm-around {
    align-content: space-around !important; }
  .align-content-sm-stretch {
    align-content: stretch !important; }
  .align-self-sm-auto {
    align-self: auto !important; }
  .align-self-sm-start {
    align-self: flex-start !important; }
  .align-self-sm-end {
    align-self: flex-end !important; }
  .align-self-sm-center {
    align-self: center !important; }
  .align-self-sm-baseline {
    align-self: baseline !important; }
  .align-self-sm-stretch {
    align-self: stretch !important; } }

@media (min-width: 768px) {
  .flex-md-row {
    flex-direction: row !important; }
  .flex-md-column {
    flex-direction: column !important; }
  .flex-md-row-reverse {
    flex-direction: row-reverse !important; }
  .flex-md-column-reverse {
    flex-direction: column-reverse !important; }
  .flex-md-wrap {
    flex-wrap: wrap !important; }
  .flex-md-nowrap {
    flex-wrap: nowrap !important; }
  .flex-md-wrap-reverse {
    flex-wrap: wrap-reverse !important; }
  .flex-md-fill {
    flex: 1 1 auto !important; }
  .flex-md-grow-0 {
    flex-grow: 0 !important; }
  .flex-md-grow-1 {
    flex-grow: 1 !important; }
  .flex-md-shrink-0 {
    flex-shrink: 0 !important; }
  .flex-md-shrink-1 {
    flex-shrink: 1 !important; }
  .justify-content-md-start {
    justify-content: flex-start !important; }
  .justify-content-md-end {
    justify-content: flex-end !important; }
  .justify-content-md-center {
    justify-content: center !important; }
  .justify-content-md-between {
    justify-content: space-between !important; }
  .justify-content-md-around {
    justify-content: space-around !important; }
  .align-items-md-start {
    align-items: flex-start !important; }
  .align-items-md-end {
    align-items: flex-end !important; }
  .align-items-md-center {
    align-items: center !important; }
  .align-items-md-baseline {
    align-items: baseline !important; }
  .align-items-md-stretch {
    align-items: stretch !important; }
  .align-content-md-start {
    align-content: flex-start !important; }
  .align-content-md-end {
    align-content: flex-end !important; }
  .align-content-md-center {
    align-content: center !important; }
  .align-content-md-between {
    align-content: space-between !important; }
  .align-content-md-around {
    align-content: space-around !important; }
  .align-content-md-stretch {
    align-content: stretch !important; }
  .align-self-md-auto {
    align-self: auto !important; }
  .align-self-md-start {
    align-self: flex-start !important; }
  .align-self-md-end {
    align-self: flex-end !important; }
  .align-self-md-center {
    align-self: center !important; }
  .align-self-md-baseline {
    align-self: baseline !important; }
  .align-self-md-stretch {
    align-self: stretch !important; } }

@media (min-width: 992px) {
  .flex-lg-row {
    flex-direction: row !important; }
  .flex-lg-column {
    flex-direction: column !important; }
  .flex-lg-row-reverse {
    flex-direction: row-reverse !important; }
  .flex-lg-column-reverse {
    flex-direction: column-reverse !important; }
  .flex-lg-wrap {
    flex-wrap: wrap !important; }
  .flex-lg-nowrap {
    flex-wrap: nowrap !important; }
  .flex-lg-wrap-reverse {
    flex-wrap: wrap-reverse !important; }
  .flex-lg-fill {
    flex: 1 1 auto !important; }
  .flex-lg-grow-0 {
    flex-grow: 0 !important; }
  .flex-lg-grow-1 {
    flex-grow: 1 !important; }
  .flex-lg-shrink-0 {
    flex-shrink: 0 !important; }
  .flex-lg-shrink-1 {
    flex-shrink: 1 !important; }
  .justify-content-lg-start {
    justify-content: flex-start !important; }
  .justify-content-lg-end {
    justify-content: flex-end !important; }
  .justify-content-lg-center {
    justify-content: center !important; }
  .justify-content-lg-between {
    justify-content: space-between !important; }
  .justify-content-lg-around {
    justify-content: space-around !important; }
  .align-items-lg-start {
    align-items: flex-start !important; }
  .align-items-lg-end {
    align-items: flex-end !important; }
  .align-items-lg-center {
    align-items: center !important; }
  .align-items-lg-baseline {
    align-items: baseline !important; }
  .align-items-lg-stretch {
    align-items: stretch !important; }
  .align-content-lg-start {
    align-content: flex-start !important; }
  .align-content-lg-end {
    align-content: flex-end !important; }
  .align-content-lg-center {
    align-content: center !important; }
  .align-content-lg-between {
    align-content: space-between !important; }
  .align-content-lg-around {
    align-content: space-around !important; }
  .align-content-lg-stretch {
    align-content: stretch !important; }
  .align-self-lg-auto {
    align-self: auto !important; }
  .align-self-lg-start {
    align-self: flex-start !important; }
  .align-self-lg-end {
    align-self: flex-end !important; }
  .align-self-lg-center {
    align-self: center !important; }
  .align-self-lg-baseline {
    align-self: baseline !important; }
  .align-self-lg-stretch {
    align-self: stretch !important; } }

@media (min-width: 1200px) {
  .flex-xl-row {
    flex-direction: row !important; }
  .flex-xl-column {
    flex-direction: column !important; }
  .flex-xl-row-reverse {
    flex-direction: row-reverse !important; }
  .flex-xl-column-reverse {
    flex-direction: column-reverse !important; }
  .flex-xl-wrap {
    flex-wrap: wrap !important; }
  .flex-xl-nowrap {
    flex-wrap: nowrap !important; }
  .flex-xl-wrap-reverse {
    flex-wrap: wrap-reverse !important; }
  .flex-xl-fill {
    flex: 1 1 auto !important; }
  .flex-xl-grow-0 {
    flex-grow: 0 !important; }
  .flex-xl-grow-1 {
    flex-grow: 1 !important; }
  .flex-xl-shrink-0 {
    flex-shrink: 0 !important; }
  .flex-xl-shrink-1 {
    flex-shrink: 1 !important; }
  .justify-content-xl-start {
    justify-content: flex-start !important; }
  .justify-content-xl-end {
    justify-content: flex-end !important; }
  .justify-content-xl-center {
    justify-content: center !important; }
  .justify-content-xl-between {
    justify-content: space-between !important; }
  .justify-content-xl-around {
    justify-content: space-around !important; }
  .align-items-xl-start {
    align-items: flex-start !important; }
  .align-items-xl-end {
    align-items: flex-end !important; }
  .align-items-xl-center {
    align-items: center !important; }
  .align-items-xl-baseline {
    align-items: baseline !important; }
  .align-items-xl-stretch {
    align-items: stretch !important; }
  .align-content-xl-start {
    align-content: flex-start !important; }
  .align-content-xl-end {
    align-content: flex-end !important; }
  .align-content-xl-center {
    align-content: center !important; }
  .align-content-xl-between {
    align-content: space-between !important; }
  .align-content-xl-around {
    align-content: space-around !important; }
  .align-content-xl-stretch {
    align-content: stretch !important; }
  .align-self-xl-auto {
    align-self: auto !important; }
  .align-self-xl-start {
    align-self: flex-start !important; }
  .align-self-xl-end {
    align-self: flex-end !important; }
  .align-self-xl-center {
    align-self: center !important; }
  .align-self-xl-baseline {
    align-self: baseline !important; }
  .align-self-xl-stretch {
    align-self: stretch !important; } }

.float-left {
  float: left !important; }

.float-right {
  float: right !important; }

.float-none {
  float: none !important; }

@media (min-width: 576px) {
  .float-sm-left {
    float: left !important; }
  .float-sm-right {
    float: right !important; }
  .float-sm-none {
    float: none !important; } }

@media (min-width: 768px) {
  .float-md-left {
    float: left !important; }
  .float-md-right {
    float: right !important; }
  .float-md-none {
    float: none !important; } }

@media (min-width: 992px) {
  .float-lg-left {
    float: left !important; }
  .float-lg-right {
    float: right !important; }
  .float-lg-none {
    float: none !important; } }

@media (min-width: 1200px) {
  .float-xl-left {
    float: left !important; }
  .float-xl-right {
    float: right !important; }
  .float-xl-none {
    float: none !important; } }

.user-select-all {
  user-select: all !important; }

.user-select-auto {
  user-select: auto !important; }

.user-select-none {
  user-select: none !important; }

.overflow-auto {
  overflow: auto !important; }

.overflow-hidden {
  overflow: hidden !important; }

.position-static {
  position: static !important; }

.position-relative {
  position: relative !important; }

.position-absolute {
  position: absolute !important; }

.position-fixed {
  position: fixed !important; }

.position-sticky {
  position: sticky !important; }

.fixed-top {
  position: fixed;
  top: 0;
  right: 0;
  left: 0;
  z-index: 1030; }

.fixed-bottom {
  position: fixed;
  right: 0;
  bottom: 0;
  left: 0;
  z-index: 1030; }

@supports (position: sticky) {
  .sticky-top {
    position: sticky;
    top: 0;
    z-index: 1020; } }

.sr-only {
  position: absolute;
  width: 1px;
  height: 1px;
  padding: 0;
  margin: -1px;
  overflow: hidden;
  clip: rect(0, 0, 0, 0);
  white-space: nowrap;
  border: 0; }

.sr-only-focusable:active, .sr-only-focusable:focus {
  position: static;
  width: auto;
  height: auto;
  overflow: visible;
  clip: auto;
  white-space: normal; }

.shadow-sm {
  box-shadow: 0 0.125rem 0.25rem rgba(0, 0, 0, 0.075) !important; }

.shadow {
  box-shadow: 0 0.5rem 1rem rgba(0, 0, 0, 0.15) !important; }

.shadow-lg {
  box-shadow: 0 1rem 3rem rgba(0, 0, 0, 0.175) !important; }

.shadow-none {
  box-shadow: none !important; }

.w-25 {
  width: 25% !important; }

.w-50 {
  width: 50% !important; }

.w-75 {
  width: 75% !important; }

.w-100 {
  width: 100% !important; }

.w-auto {
  width: auto !important; }

.h-25 {
  height: 25% !important; }

.h-50 {
  height: 50% !important; }

.h-75 {
  height: 75% !important; }

.h-100 {
  height: 100% !important; }

.h-auto {
  height: auto !important; }

.mw-100 {
  max-width: 100% !important; }

.mh-100 {
  max-height: 100% !important; }

.min-vw-100 {
  min-width: 100vw !important; }

.min-vh-100 {
  min-height: 100vh !important; }

.vw-100 {
  width: 100vw !important; }

.vh-100 {
  height: 100vh !important; }

.m-0 {
  margin: 0 !important; }

.mt-0,
.my-0 {
  margin-top: 0 !important; }

.mr-0,
.mx-0 {
  margin-right: 0 !important; }

.mb-0,
.my-0 {
  margin-bottom: 0 !important; }

.ml-0,
.mx-0 {
  margin-left: 0 !important; }

.m-1 {
  margin: 0.25rem !important; }

.mt-1,
.my-1 {
  margin-top: 0.25rem !important; }

.mr-1,
.mx-1 {
  margin-right: 0.25rem !important; }

.mb-1,
.my-1 {
  margin-bottom: 0.25rem !important; }

.ml-1,
.mx-1 {
  margin-left: 0.25rem !important; }

.m-2 {
  margin: 0.5rem !important; }

.mt-2,
.my-2 {
  margin-top: 0.5rem !important; }

.mr-2,
.mx-2 {
  margin-right: 0.5rem !important; }

.mb-2,
.my-2 {
  margin-bottom: 0.5rem !important; }

.ml-2,
.mx-2 {
  margin-left: 0.5rem !important; }

.m-3 {
  margin: 1rem !important; }

.mt-3,
.my-3 {
  margin-top: 1rem !important; }

.mr-3,
.mx-3 {
  margin-right: 1rem !important; }

.mb-3,
.my-3 {
  margin-bottom: 1rem !important; }

.ml-3,
.mx-3 {
  margin-left: 1rem !important; }

.m-4 {
  margin: 1.5rem !important; }

.mt-4,
.my-4 {
  margin-top: 1.5rem !important; }

.mr-4,
.mx-4 {
  margin-right: 1.5rem !important; }

.mb-4,
.my-4 {
  margin-bottom: 1.5rem !important; }

.ml-4,
.mx-4 {
  margin-left: 1.5rem !important; }

.m-5 {
  margin: 3rem !important; }

.mt-5,
.my-5 {
  margin-top: 3rem !important; }

.mr-5,
.mx-5 {
  margin-right: 3rem !important; }

.mb-5,
.my-5 {
  margin-bottom: 3rem !important; }

.ml-5,
.mx-5 {
  margin-left: 3rem !important; }

.p-0 {
  padding: 0 !important; }

.pt-0,
.py-0 {
  padding-top: 0 !important; }

.pr-0,
.px-0 {
  padding-right: 0 !important; }

.pb-0,
.py-0 {
  padding-bottom: 0 !important; }

.pl-0,
.px-0 {
  padding-left: 0 !important; }

.p-1 {
  padding: 0.25rem !important; }

.pt-1,
.py-1 {
  padding-top: 0.25rem !important; }

.pr-1,
.px-1 {
  padding-right: 0.25rem !important; }

.pb-1,
.py-1 {
  padding-bottom: 0.25rem !important; }

.pl-1,
.px-1 {
  padding-left: 0.25rem !important; }

.p-2 {
  padding: 0.5rem !important; }

.pt-2,
.py-2 {
  padding-top: 0.5rem !important; }

.pr-2,
.px-2 {
  padding-right: 0.5rem !important; }

.pb-2,
.py-2 {
  padding-bottom: 0.5rem !important; }

.pl-2,
.px-2 {
  padding-left: 0.5rem !important; }

.p-3 {
  padding: 1rem !important; }

.pt-3,
.py-3 {
  padding-top: 1rem !important; }

.pr-3,
.px-3 {
  padding-right: 1rem !important; }

.pb-3,
.py-3 {
  padding-bottom: 1rem !important; }

.pl-3,
.px-3 {
  padding-left: 1rem !important; }

.p-4 {
  padding: 1.5rem !important; }

.pt-4,
.py-4 {
  padding-top: 1.5rem !important; }

.pr-4,
.px-4 {
  padding-right: 1.5rem !important; }

.pb-4,
.py-4 {
  padding-bottom: 1.5rem !important; }

.pl-4,
.px-4 {
  padding-left: 1.5rem !important; }

.p-5 {
  padding: 3rem !important; }

.pt-5,
.py-5 {
  padding-top: 3rem !important; }

.pr-5,
.px-5 {
  padding-right: 3rem !important; }

.pb-5,
.py-5 {
  padding-bottom: 3rem !important; }

.pl-5,
.px-5 {
  padding-left: 3rem !important; }

.m-n1 {
  margin: -0.25rem !important; }

.mt-n1,
.my-n1 {
  margin-top: -0.25rem !important; }

.mr-n1,
.mx-n1 {
  margin-right: -0.25rem !important; }

.mb-n1,
.my-n1 {
  margin-bottom: -0.25rem !important; }

.ml-n1,
.mx-n1 {
  margin-left: -0.25rem !important; }

.m-n2 {
  margin: -0.5rem !important; }

.mt-n2,
.my-n2 {
  margin-top: -0.5rem !important; }

.mr-n2,
.mx-n2 {
  margin-right: -0.5rem !important; }

.mb-n2,
.my-n2 {
  margin-bottom: -0.5rem !important; }

.ml-n2,
.mx-n2 {
  margin-left: -0.5rem !important; }

.m-n3 {
  margin: -1rem !important; }

.mt-n3,
.my-n3 {
  margin-top: -1rem !important; }

.mr-n3,
.mx-n3 {
  margin-right: -1rem !important; }

.mb-n3,
.my-n3 {
  margin-bottom: -1rem !important; }

.ml-n3,
.mx-n3 {
  margin-left: -1rem !important; }

.m-n4 {
  margin: -1.5rem !important; }

.mt-n4,
.my-n4 {
  margin-top: -1.5rem !important; }

.mr-n4,
.mx-n4 {
  margin-right: -1.5rem !important; }

.mb-n4,
.my-n4 {
  margin-bottom: -1.5rem !important; }

.ml-n4,
.mx-n4 {
  margin-left: -1.5rem !important; }

.m-n5 {
  margin: -3rem !important; }

.mt-n5,
.my-n5 {
  margin-top: -3rem !important; }

.mr-n5,
.mx-n5 {
  margin-right: -3rem !important; }

.mb-n5,
.my-n5 {
  margin-bottom: -3rem !important; }

.ml-n5,
.mx-n5 {
  margin-left: -3rem !important; }

.m-auto {
  margin: auto !important; }

.mt-auto,
.my-auto {
  margin-top: auto !important; }

.mr-auto,
.mx-auto {
  margin-right: auto !important; }

.mb-auto,
.my-auto {
  margin-bottom: auto !important; }

.ml-auto,
.mx-auto {
  margin-left: auto !important; }

@media (min-width: 576px) {
  .m-sm-0 {
    margin: 0 !important; }
  .mt-sm-0,
  .my-sm-0 {
    margin-top: 0 !important; }
  .mr-sm-0,
  .mx-sm-0 {
    margin-right: 0 !important; }
  .mb-sm-0,
  .my-sm-0 {
    margin-bottom: 0 !important; }
  .ml-sm-0,
  .mx-sm-0 {
    margin-left: 0 !important; }
  .m-sm-1 {
    margin: 0.25rem !important; }
  .mt-sm-1,
  .my-sm-1 {
    margin-top: 0.25rem !important; }
  .mr-sm-1,
  .mx-sm-1 {
    margin-right: 0.25rem !important; }
  .mb-sm-1,
  .my-sm-1 {
    margin-bottom: 0.25rem !important; }
  .ml-sm-1,
  .mx-sm-1 {
    margin-left: 0.25rem !important; }
  .m-sm-2 {
    margin: 0.5rem !important; }
  .mt-sm-2,
  .my-sm-2 {
    margin-top: 0.5rem !important; }
  .mr-sm-2,
  .mx-sm-2 {
    margin-right: 0.5rem !important; }
  .mb-sm-2,
  .my-sm-2 {
    margin-bottom: 0.5rem !important; }
  .ml-sm-2,
  .mx-sm-2 {
    margin-left: 0.5rem !important; }
  .m-sm-3 {
    margin: 1rem !important; }
  .mt-sm-3,
  .my-sm-3 {
    margin-top: 1rem !important; }
  .mr-sm-3,
  .mx-sm-3 {
    margin-right: 1rem !important; }
  .mb-sm-3,
  .my-sm-3 {
    margin-bottom: 1rem !important; }
  .ml-sm-3,
  .mx-sm-3 {
    margin-left: 1rem !important; }
  .m-sm-4 {
    margin: 1.5rem !important; }
  .mt-sm-4,
  .my-sm-4 {
    margin-top: 1.5rem !important; }
  .mr-sm-4,
  .mx-sm-4 {
    margin-right: 1.5rem !important; }
  .mb-sm-4,
  .my-sm-4 {
    margin-bottom: 1.5rem !important; }
  .ml-sm-4,
  .mx-sm-4 {
    margin-left: 1.5rem !important; }
  .m-sm-5 {
    margin: 3rem !important; }
  .mt-sm-5,
  .my-sm-5 {
    margin-top: 3rem !important; }
  .mr-sm-5,
  .mx-sm-5 {
    margin-right: 3rem !important; }
  .mb-sm-5,
  .my-sm-5 {
    margin-bottom: 3rem !important; }
  .ml-sm-5,
  .mx-sm-5 {
    margin-left: 3rem !important; }
  .p-sm-0 {
    padding: 0 !important; }
  .pt-sm-0,
  .py-sm-0 {
    padding-top: 0 !important; }
  .pr-sm-0,
  .px-sm-0 {
    padding-right: 0 !important; }
  .pb-sm-0,
  .py-sm-0 {
    padding-bottom: 0 !important; }
  .pl-sm-0,
  .px-sm-0 {
    padding-left: 0 !important; }
  .p-sm-1 {
    padding: 0.25rem !important; }
  .pt-sm-1,
  .py-sm-1 {
    padding-top: 0.25rem !important; }
  .pr-sm-1,
  .px-sm-1 {
    padding-right: 0.25rem !important; }
  .pb-sm-1,
  .py-sm-1 {
    padding-bottom: 0.25rem !important; }
  .pl-sm-1,
  .px-sm-1 {
    padding-left: 0.25rem !important; }
  .p-sm-2 {
    padding: 0.5rem !important; }
  .pt-sm-2,
  .py-sm-2 {
    padding-top: 0.5rem !important; }
  .pr-sm-2,
  .px-sm-2 {
    padding-right: 0.5rem !important; }
  .pb-sm-2,
  .py-sm-2 {
    padding-bottom: 0.5rem !important; }
  .pl-sm-2,
  .px-sm-2 {
    padding-left: 0.5rem !important; }
  .p-sm-3 {
    padding: 1rem !important; }
  .pt-sm-3,
  .py-sm-3 {
    padding-top: 1rem !important; }
  .pr-sm-3,
  .px-sm-3 {
    padding-right: 1rem !important; }
  .pb-sm-3,
  .py-sm-3 {
    padding-bottom: 1rem !important; }
  .pl-sm-3,
  .px-sm-3 {
    padding-left: 1rem !important; }
  .p-sm-4 {
    padding: 1.5rem !important; }
  .pt-sm-4,
  .py-sm-4 {
    padding-top: 1.5rem !important; }
  .pr-sm-4,
  .px-sm-4 {
    padding-right: 1.5rem !important; }
  .pb-sm-4,
  .py-sm-4 {
    padding-bottom: 1.5rem !important; }
  .pl-sm-4,
  .px-sm-4 {
    padding-left: 1.5rem !important; }
  .p-sm-5 {
    padding: 3rem !important; }
  .pt-sm-5,
  .py-sm-5 {
    padding-top: 3rem !important; }
  .pr-sm-5,
  .px-sm-5 {
    padding-right: 3rem !important; }
  .pb-sm-5,
  .py-sm-5 {
    padding-bottom: 3rem !important; }
  .pl-sm-5,
  .px-sm-5 {
    padding-left: 3rem !important; }
  .m-sm-n1 {
    margin: -0.25rem !important; }
  .mt-sm-n1,
  .my-sm-n1 {
    margin-top: -0.25rem !important; }
  .mr-sm-n1,
  .mx-sm-n1 {
    margin-right: -0.25rem !important; }
  .mb-sm-n1,
  .my-sm-n1 {
    margin-bottom: -0.25rem !important; }
  .ml-sm-n1,
  .mx-sm-n1 {
    margin-left: -0.25rem !important; }
  .m-sm-n2 {
    margin: -0.5rem !important; }
  .mt-sm-n2,
  .my-sm-n2 {
    margin-top: -0.5rem !important; }
  .mr-sm-n2,
  .mx-sm-n2 {
    margin-right: -0.5rem !important; }
  .mb-sm-n2,
  .my-sm-n2 {
    margin-bottom: -0.5rem !important; }
  .ml-sm-n2,
  .mx-sm-n2 {
    margin-left: -0.5rem !important; }
  .m-sm-n3 {
    margin: -1rem !important; }
  .mt-sm-n3,
  .my-sm-n3 {
    margin-top: -1rem !important; }
  .mr-sm-n3,
  .mx-sm-n3 {
    margin-right: -1rem !important; }
  .mb-sm-n3,
  .my-sm-n3 {
    margin-bottom: -1rem !important; }
  .ml-sm-n3,
  .mx-sm-n3 {
    margin-left: -1rem !important; }
  .m-sm-n4 {
    margin: -1.5rem !important; }
  .mt-sm-n4,
  .my-sm-n4 {
    margin-top: -1.5rem !important; }
  .mr-sm-n4,
  .mx-sm-n4 {
    margin-right: -1.5rem !important; }
  .mb-sm-n4,
  .my-sm-n4 {
    margin-bottom: -1.5rem !important; }
  .ml-sm-n4,
  .mx-sm-n4 {
    margin-left: -1.5rem !important; }
  .m-sm-n5 {
    margin: -3rem !important; }
  .mt-sm-n5,
  .my-sm-n5 {
    margin-top: -3rem !important; }
  .mr-sm-n5,
  .mx-sm-n5 {
    margin-right: -3rem !important; }
  .mb-sm-n5,
  .my-sm-n5 {
    margin-bottom: -3rem !important; }
  .ml-sm-n5,
  .mx-sm-n5 {
    margin-left: -3rem !important; }
  .m-sm-auto {
    margin: auto !important; }
  .mt-sm-auto,
  .my-sm-auto {
    margin-top: auto !important; }
  .mr-sm-auto,
  .mx-sm-auto {
    margin-right: auto !important; }
  .mb-sm-auto,
  .my-sm-auto {
    margin-bottom: auto !important; }
  .ml-sm-auto,
  .mx-sm-auto {
    margin-left: auto !important; } }

@media (min-width: 768px) {
  .m-md-0 {
    margin: 0 !important; }
  .mt-md-0,
  .my-md-0 {
    margin-top: 0 !important; }
  .mr-md-0,
  .mx-md-0 {
    margin-right: 0 !important; }
  .mb-md-0,
  .my-md-0 {
    margin-bottom: 0 !important; }
  .ml-md-0,
  .mx-md-0 {
    margin-left: 0 !important; }
  .m-md-1 {
    margin: 0.25rem !important; }
  .mt-md-1,
  .my-md-1 {
    margin-top: 0.25rem !important; }
  .mr-md-1,
  .mx-md-1 {
    margin-right: 0.25rem !important; }
  .mb-md-1,
  .my-md-1 {
    margin-bottom: 0.25rem !important; }
  .ml-md-1,
  .mx-md-1 {
    margin-left: 0.25rem !important; }
  .m-md-2 {
    margin: 0.5rem !important; }
  .mt-md-2,
  .my-md-2 {
    margin-top: 0.5rem !important; }
  .mr-md-2,
  .mx-md-2 {
    margin-right: 0.5rem !important; }
  .mb-md-2,
  .my-md-2 {
    margin-bottom: 0.5rem !important; }
  .ml-md-2,
  .mx-md-2 {
    margin-left: 0.5rem !important; }
  .m-md-3 {
    margin: 1rem !important; }
  .mt-md-3,
  .my-md-3 {
    margin-top: 1rem !important; }
  .mr-md-3,
  .mx-md-3 {
    margin-right: 1rem !important; }
  .mb-md-3,
  .my-md-3 {
    margin-bottom: 1rem !important; }
  .ml-md-3,
  .mx-md-3 {
    margin-left: 1rem !important; }
  .m-md-4 {
    margin: 1.5rem !important; }
  .mt-md-4,
  .my-md-4 {
    margin-top: 1.5rem !important; }
  .mr-md-4,
  .mx-md-4 {
    margin-right: 1.5rem !important; }
  .mb-md-4,
  .my-md-4 {
    margin-bottom: 1.5rem !important; }
  .ml-md-4,
  .mx-md-4 {
    margin-left: 1.5rem !important; }
  .m-md-5 {
    margin: 3rem !important; }
  .mt-md-5,
  .my-md-5 {
    margin-top: 3rem !important; }
  .mr-md-5,
  .mx-md-5 {
    margin-right: 3rem !important; }
  .mb-md-5,
  .my-md-5 {
    margin-bottom: 3rem !important; }
  .ml-md-5,
  .mx-md-5 {
    margin-left: 3rem !important; }
  .p-md-0 {
    padding: 0 !important; }
  .pt-md-0,
  .py-md-0 {
    padding-top: 0 !important; }
  .pr-md-0,
  .px-md-0 {
    padding-right: 0 !important; }
  .pb-md-0,
  .py-md-0 {
    padding-bottom: 0 !important; }
  .pl-md-0,
  .px-md-0 {
    padding-left: 0 !important; }
  .p-md-1 {
    padding: 0.25rem !important; }
  .pt-md-1,
  .py-md-1 {
    padding-top: 0.25rem !important; }
  .pr-md-1,
  .px-md-1 {
    padding-right: 0.25rem !important; }
  .pb-md-1,
  .py-md-1 {
    padding-bottom: 0.25rem !important; }
  .pl-md-1,
  .px-md-1 {
    padding-left: 0.25rem !important; }
  .p-md-2 {
    padding: 0.5rem !important; }
  .pt-md-2,
  .py-md-2 {
    padding-top: 0.5rem !important; }
  .pr-md-2,
  .px-md-2 {
    padding-right: 0.5rem !important; }
  .pb-md-2,
  .py-md-2 {
    padding-bottom: 0.5rem !important; }
  .pl-md-2,
  .px-md-2 {
    padding-left: 0.5rem !important; }
  .p-md-3 {
    padding: 1rem !important; }
  .pt-md-3,
  .py-md-3 {
    padding-top: 1rem !important; }
  .pr-md-3,
  .px-md-3 {
    padding-right: 1rem !important; }
  .pb-md-3,
  .py-md-3 {
    padding-bottom: 1rem !important; }
  .pl-md-3,
  .px-md-3 {
    padding-left: 1rem !important; }
  .p-md-4 {
    padding: 1.5rem !important; }
  .pt-md-4,
  .py-md-4 {
    padding-top: 1.5rem !important; }
  .pr-md-4,
  .px-md-4 {
    padding-right: 1.5rem !important; }
  .pb-md-4,
  .py-md-4 {
    padding-bottom: 1.5rem !important; }
  .pl-md-4,
  .px-md-4 {
    padding-left: 1.5rem !important; }
  .p-md-5 {
    padding: 3rem !important; }
  .pt-md-5,
  .py-md-5 {
    padding-top: 3rem !important; }
  .pr-md-5,
  .px-md-5 {
    padding-right: 3rem !important; }
  .pb-md-5,
  .py-md-5 {
    padding-bottom: 3rem !important; }
  .pl-md-5,
  .px-md-5 {
    padding-left: 3rem !important; }
  .m-md-n1 {
    margin: -0.25rem !important; }
  .mt-md-n1,
  .my-md-n1 {
    margin-top: -0.25rem !important; }
  .mr-md-n1,
  .mx-md-n1 {
    margin-right: -0.25rem !important; }
  .mb-md-n1,
  .my-md-n1 {
    margin-bottom: -0.25rem !important; }
  .ml-md-n1,
  .mx-md-n1 {
    margin-left: -0.25rem !important; }
  .m-md-n2 {
    margin: -0.5rem !important; }
  .mt-md-n2,
  .my-md-n2 {
    margin-top: -0.5rem !important; }
  .mr-md-n2,
  .mx-md-n2 {
    margin-right: -0.5rem !important; }
  .mb-md-n2,
  .my-md-n2 {
    margin-bottom: -0.5rem !important; }
  .ml-md-n2,
  .mx-md-n2 {
    margin-left: -0.5rem !important; }
  .m-md-n3 {
    margin: -1rem !important; }
  .mt-md-n3,
  .my-md-n3 {
    margin-top: -1rem !important; }
  .mr-md-n3,
  .mx-md-n3 {
    margin-right: -1rem !important; }
  .mb-md-n3,
  .my-md-n3 {
    margin-bottom: -1rem !important; }
  .ml-md-n3,
  .mx-md-n3 {
    margin-left: -1rem !important; }
  .m-md-n4 {
    margin: -1.5rem !important; }
  .mt-md-n4,
  .my-md-n4 {
    margin-top: -1.5rem !important; }
  .mr-md-n4,
  .mx-md-n4 {
    margin-right: -1.5rem !important; }
  .mb-md-n4,
  .my-md-n4 {
    margin-bottom: -1.5rem !important; }
  .ml-md-n4,
  .mx-md-n4 {
    margin-left: -1.5rem !important; }
  .m-md-n5 {
    margin: -3rem !important; }
  .mt-md-n5,
  .my-md-n5 {
    margin-top: -3rem !important; }
  .mr-md-n5,
  .mx-md-n5 {
    margin-right: -3rem !important; }
  .mb-md-n5,
  .my-md-n5 {
    margin-bottom: -3rem !important; }
  .ml-md-n5,
  .mx-md-n5 {
    margin-left: -3rem !important; }
  .m-md-auto {
    margin: auto !important; }
  .mt-md-auto,
  .my-md-auto {
    margin-top: auto !important; }
  .mr-md-auto,
  .mx-md-auto {
    margin-right: auto !important; }
  .mb-md-auto,
  .my-md-auto {
    margin-bottom: auto !important; }
  .ml-md-auto,
  .mx-md-auto {
    margin-left: auto !important; } }

@media (min-width: 992px) {
  .m-lg-0 {
    margin: 0 !important; }
  .mt-lg-0,
  .my-lg-0 {
    margin-top: 0 !important; }
  .mr-lg-0,
  .mx-lg-0 {
    margin-right: 0 !important; }
  .mb-lg-0,
  .my-lg-0 {
    margin-bottom: 0 !important; }
  .ml-lg-0,
  .mx-lg-0 {
    margin-left: 0 !important; }
  .m-lg-1 {
    margin: 0.25rem !important; }
  .mt-lg-1,
  .my-lg-1 {
    margin-top: 0.25rem !important; }
  .mr-lg-1,
  .mx-lg-1 {
    margin-right: 0.25rem !important; }
  .mb-lg-1,
  .my-lg-1 {
    margin-bottom: 0.25rem !important; }
  .ml-lg-1,
  .mx-lg-1 {
    margin-left: 0.25rem !important; }
  .m-lg-2 {
    margin: 0.5rem !important; }
  .mt-lg-2,
  .my-lg-2 {
    margin-top: 0.5rem !important; }
  .mr-lg-2,
  .mx-lg-2 {
    margin-right: 0.5rem !important; }
  .mb-lg-2,
  .my-lg-2 {
    margin-bottom: 0.5rem !important; }
  .ml-lg-2,
  .mx-lg-2 {
    margin-left: 0.5rem !important; }
  .m-lg-3 {
    margin: 1rem !important; }
  .mt-lg-3,
  .my-lg-3 {
    margin-top: 1rem !important; }
  .mr-lg-3,
  .mx-lg-3 {
    margin-right: 1rem !important; }
  .mb-lg-3,
  .my-lg-3 {
    margin-bottom: 1rem !important; }
  .ml-lg-3,
  .mx-lg-3 {
    margin-left: 1rem !important; }
  .m-lg-4 {
    margin: 1.5rem !important; }
  .mt-lg-4,
  .my-lg-4 {
    margin-top: 1.5rem !important; }
  .mr-lg-4,
  .mx-lg-4 {
    margin-right: 1.5rem !important; }
  .mb-lg-4,
  .my-lg-4 {
    margin-bottom: 1.5rem !important; }
  .ml-lg-4,
  .mx-lg-4 {
    margin-left: 1.5rem !important; }
  .m-lg-5 {
    margin: 3rem !important; }
  .mt-lg-5,
  .my-lg-5 {
    margin-top: 3rem !important; }
  .mr-lg-5,
  .mx-lg-5 {
    margin-right: 3rem !important; }
  .mb-lg-5,
  .my-lg-5 {
    margin-bottom: 3rem !important; }
  .ml-lg-5,
  .mx-lg-5 {
    margin-left: 3rem !important; }
  .p-lg-0 {
    padding: 0 !important; }
  .pt-lg-0,
  .py-lg-0 {
    padding-top: 0 !important; }
  .pr-lg-0,
  .px-lg-0 {
    padding-right: 0 !important; }
  .pb-lg-0,
  .py-lg-0 {
    padding-bottom: 0 !important; }
  .pl-lg-0,
  .px-lg-0 {
    padding-left: 0 !important; }
  .p-lg-1 {
    padding: 0.25rem !important; }
  .pt-lg-1,
  .py-lg-1 {
    padding-top: 0.25rem !important; }
  .pr-lg-1,
  .px-lg-1 {
    padding-right: 0.25rem !important; }
  .pb-lg-1,
  .py-lg-1 {
    padding-bottom: 0.25rem !important; }
  .pl-lg-1,
  .px-lg-1 {
    padding-left: 0.25rem !important; }
  .p-lg-2 {
    padding: 0.5rem !important; }
  .pt-lg-2,
  .py-lg-2 {
    padding-top: 0.5rem !important; }
  .pr-lg-2,
  .px-lg-2 {
    padding-right: 0.5rem !important; }
  .pb-lg-2,
  .py-lg-2 {
    padding-bottom: 0.5rem !important; }
  .pl-lg-2,
  .px-lg-2 {
    padding-left: 0.5rem !important; }
  .p-lg-3 {
    padding: 1rem !important; }
  .pt-lg-3,
  .py-lg-3 {
    padding-top: 1rem !important; }
  .pr-lg-3,
  .px-lg-3 {
    padding-right: 1rem !important; }
  .pb-lg-3,
  .py-lg-3 {
    padding-bottom: 1rem !important; }
  .pl-lg-3,
  .px-lg-3 {
    padding-left: 1rem !important; }
  .p-lg-4 {
    padding: 1.5rem !important; }
  .pt-lg-4,
  .py-lg-4 {
    padding-top: 1.5rem !important; }
  .pr-lg-4,
  .px-lg-4 {
    padding-right: 1.5rem !important; }
  .pb-lg-4,
  .py-lg-4 {
    padding-bottom: 1.5rem !important; }
  .pl-lg-4,
  .px-lg-4 {
    padding-left: 1.5rem !important; }
  .p-lg-5 {
    padding: 3rem !important; }
  .pt-lg-5,
  .py-lg-5 {
    padding-top: 3rem !important; }
  .pr-lg-5,
  .px-lg-5 {
    padding-right: 3rem !important; }
  .pb-lg-5,
  .py-lg-5 {
    padding-bottom: 3rem !important; }
  .pl-lg-5,
  .px-lg-5 {
    padding-left: 3rem !important; }
  .m-lg-n1 {
    margin: -0.25rem !important; }
  .mt-lg-n1,
  .my-lg-n1 {
    margin-top: -0.25rem !important; }
  .mr-lg-n1,
  .mx-lg-n1 {
    margin-right: -0.25rem !important; }
  .mb-lg-n1,
  .my-lg-n1 {
    margin-bottom: -0.25rem !important; }
  .ml-lg-n1,
  .mx-lg-n1 {
    margin-left: -0.25rem !important; }
  .m-lg-n2 {
    margin: -0.5rem !important; }
  .mt-lg-n2,
  .my-lg-n2 {
    margin-top: -0.5rem !important; }
  .mr-lg-n2,
  .mx-lg-n2 {
    margin-right: -0.5rem !important; }
  .mb-lg-n2,
  .my-lg-n2 {
    margin-bottom: -0.5rem !important; }
  .ml-lg-n2,
  .mx-lg-n2 {
    margin-left: -0.5rem !important; }
  .m-lg-n3 {
    margin: -1rem !important; }
  .mt-lg-n3,
  .my-lg-n3 {
    margin-top: -1rem !important; }
  .mr-lg-n3,
  .mx-lg-n3 {
    margin-right: -1rem !important; }
  .mb-lg-n3,
  .my-lg-n3 {
    margin-bottom: -1rem !important; }
  .ml-lg-n3,
  .mx-lg-n3 {
    margin-left: -1rem !important; }
  .m-lg-n4 {
    margin: -1.5rem !important; }
  .mt-lg-n4,
  .my-lg-n4 {
    margin-top: -1.5rem !important; }
  .mr-lg-n4,
  .mx-lg-n4 {
    margin-right: -1.5rem !important; }
  .mb-lg-n4,
  .my-lg-n4 {
    margin-bottom: -1.5rem !important; }
  .ml-lg-n4,
  .mx-lg-n4 {
    margin-left: -1.5rem !important; }
  .m-lg-n5 {
    margin: -3rem !important; }
  .mt-lg-n5,
  .my-lg-n5 {
    margin-top: -3rem !important; }
  .mr-lg-n5,
  .mx-lg-n5 {
    margin-right: -3rem !important; }
  .mb-lg-n5,
  .my-lg-n5 {
    margin-bottom: -3rem !important; }
  .ml-lg-n5,
  .mx-lg-n5 {
    margin-left: -3rem !important; }
  .m-lg-auto {
    margin: auto !important; }
  .mt-lg-auto,
  .my-lg-auto {
    margin-top: auto !important; }
  .mr-lg-auto,
  .mx-lg-auto {
    margin-right: auto !important; }
  .mb-lg-auto,
  .my-lg-auto {
    margin-bottom: auto !important; }
  .ml-lg-auto,
  .mx-lg-auto {
    margin-left: auto !important; } }

@media (min-width: 1200px) {
  .m-xl-0 {
    margin: 0 !important; }
  .mt-xl-0,
  .my-xl-0 {
    margin-top: 0 !important; }
  .mr-xl-0,
  .mx-xl-0 {
    margin-right: 0 !important; }
  .mb-xl-0,
  .my-xl-0 {
    margin-bottom: 0 !important; }
  .ml-xl-0,
  .mx-xl-0 {
    margin-left: 0 !important; }
  .m-xl-1 {
    margin: 0.25rem !important; }
  .mt-xl-1,
  .my-xl-1 {
    margin-top: 0.25rem !important; }
  .mr-xl-1,
  .mx-xl-1 {
    margin-right: 0.25rem !important; }
  .mb-xl-1,
  .my-xl-1 {
    margin-bottom: 0.25rem !important; }
  .ml-xl-1,
  .mx-xl-1 {
    margin-left: 0.25rem !important; }
  .m-xl-2 {
    margin: 0.5rem !important; }
  .mt-xl-2,
  .my-xl-2 {
    margin-top: 0.5rem !important; }
  .mr-xl-2,
  .mx-xl-2 {
    margin-right: 0.5rem !important; }
  .mb-xl-2,
  .my-xl-2 {
    margin-bottom: 0.5rem !important; }
  .ml-xl-2,
  .mx-xl-2 {
    margin-left: 0.5rem !important; }
  .m-xl-3 {
    margin: 1rem !important; }
  .mt-xl-3,
  .my-xl-3 {
    margin-top: 1rem !important; }
  .mr-xl-3,
  .mx-xl-3 {
    margin-right: 1rem !important; }
  .mb-xl-3,
  .my-xl-3 {
    margin-bottom: 1rem !important; }
  .ml-xl-3,
  .mx-xl-3 {
    margin-left: 1rem !important; }
  .m-xl-4 {
    margin: 1.5rem !important; }
  .mt-xl-4,
  .my-xl-4 {
    margin-top: 1.5rem !important; }
  .mr-xl-4,
  .mx-xl-4 {
    margin-right: 1.5rem !important; }
  .mb-xl-4,
  .my-xl-4 {
    margin-bottom: 1.5rem !important; }
  .ml-xl-4,
  .mx-xl-4 {
    margin-left: 1.5rem !important; }
  .m-xl-5 {
    margin: 3rem !important; }
  .mt-xl-5,
  .my-xl-5 {
    margin-top: 3rem !important; }
  .mr-xl-5,
  .mx-xl-5 {
    margin-right: 3rem !important; }
  .mb-xl-5,
  .my-xl-5 {
    margin-bottom: 3rem !important; }
  .ml-xl-5,
  .mx-xl-5 {
    margin-left: 3rem !important; }
  .p-xl-0 {
    padding: 0 !important; }
  .pt-xl-0,
  .py-xl-0 {
    padding-top: 0 !important; }
  .pr-xl-0,
  .px-xl-0 {
    padding-right: 0 !important; }
  .pb-xl-0,
  .py-xl-0 {
    padding-bottom: 0 !important; }
  .pl-xl-0,
  .px-xl-0 {
    padding-left: 0 !important; }
  .p-xl-1 {
    padding: 0.25rem !important; }
  .pt-xl-1,
  .py-xl-1 {
    padding-top: 0.25rem !important; }
  .pr-xl-1,
  .px-xl-1 {
    padding-right: 0.25rem !important; }
  .pb-xl-1,
  .py-xl-1 {
    padding-bottom: 0.25rem !important; }
  .pl-xl-1,
  .px-xl-1 {
    padding-left: 0.25rem !important; }
  .p-xl-2 {
    padding: 0.5rem !important; }
  .pt-xl-2,
  .py-xl-2 {
    padding-top: 0.5rem !important; }
  .pr-xl-2,
  .px-xl-2 {
    padding-right: 0.5rem !important; }
  .pb-xl-2,
  .py-xl-2 {
    padding-bottom: 0.5rem !important; }
  .pl-xl-2,
  .px-xl-2 {
    padding-left: 0.5rem !important; }
  .p-xl-3 {
    padding: 1rem !important; }
  .pt-xl-3,
  .py-xl-3 {
    padding-top: 1rem !important; }
  .pr-xl-3,
  .px-xl-3 {
    padding-right: 1rem !important; }
  .pb-xl-3,
  .py-xl-3 {
    padding-bottom: 1rem !important; }
  .pl-xl-3,
  .px-xl-3 {
    padding-left: 1rem !important; }
  .p-xl-4 {
    padding: 1.5rem !important; }
  .pt-xl-4,
  .py-xl-4 {
    padding-top: 1.5rem !important; }
  .pr-xl-4,
  .px-xl-4 {
    padding-right: 1.5rem !important; }
  .pb-xl-4,
  .py-xl-4 {
    padding-bottom: 1.5rem !important; }
  .pl-xl-4,
  .px-xl-4 {
    padding-left: 1.5rem !important; }
  .p-xl-5 {
    padding: 3rem !important; }
  .pt-xl-5,
  .py-xl-5 {
    padding-top: 3rem !important; }
  .pr-xl-5,
  .px-xl-5 {
    padding-right: 3rem !important; }
  .pb-xl-5,
  .py-xl-5 {
    padding-bottom: 3rem !important; }
  .pl-xl-5,
  .px-xl-5 {
    padding-left: 3rem !important; }
  .m-xl-n1 {
    margin: -0.25rem !important; }
  .mt-xl-n1,
  .my-xl-n1 {
    margin-top: -0.25rem !important; }
  .mr-xl-n1,
  .mx-xl-n1 {
    margin-right: -0.25rem !important; }
  .mb-xl-n1,
  .my-xl-n1 {
    margin-bottom: -0.25rem !important; }
  .ml-xl-n1,
  .mx-xl-n1 {
    margin-left: -0.25rem !important; }
  .m-xl-n2 {
    margin: -0.5rem !important; }
  .mt-xl-n2,
  .my-xl-n2 {
    margin-top: -0.5rem !important; }
  .mr-xl-n2,
  .mx-xl-n2 {
    margin-right: -0.5rem !important; }
  .mb-xl-n2,
  .my-xl-n2 {
    margin-bottom: -0.5rem !important; }
  .ml-xl-n2,
  .mx-xl-n2 {
    margin-left: -0.5rem !important; }
  .m-xl-n3 {
    margin: -1rem !important; }
  .mt-xl-n3,
  .my-xl-n3 {
    margin-top: -1rem !important; }
  .mr-xl-n3,
  .mx-xl-n3 {
    margin-right: -1rem !important; }
  .mb-xl-n3,
  .my-xl-n3 {
    margin-bottom: -1rem !important; }
  .ml-xl-n3,
  .mx-xl-n3 {
    margin-left: -1rem !important; }
  .m-xl-n4 {
    margin: -1.5rem !important; }
  .mt-xl-n4,
  .my-xl-n4 {
    margin-top: -1.5rem !important; }
  .mr-xl-n4,
  .mx-xl-n4 {
    margin-right: -1.5rem !important; }
  .mb-xl-n4,
  .my-xl-n4 {
    margin-bottom: -1.5rem !important; }
  .ml-xl-n4,
  .mx-xl-n4 {
    margin-left: -1.5rem !important; }
  .m-xl-n5 {
    margin: -3rem !important; }
  .mt-xl-n5,
  .my-xl-n5 {
    margin-top: -3rem !important; }
  .mr-xl-n5,
  .mx-xl-n5 {
    margin-right: -3rem !important; }
  .mb-xl-n5,
  .my-xl-n5 {
    margin-bottom: -3rem !important; }
  .ml-xl-n5,
  .mx-xl-n5 {
    margin-left: -3rem !important; }
  .m-xl-auto {
    margin: auto !important; }
  .mt-xl-auto,
  .my-xl-auto {
    margin-top: auto !important; }
  .mr-xl-auto,
  .mx-xl-auto {
    margin-right: auto !important; }
  .mb-xl-auto,
  .my-xl-auto {
    margin-bottom: auto !important; }
  .ml-xl-auto,
  .mx-xl-auto {
    margin-left: auto !important; } }

.stretched-link::after {
  position: absolute;
  top: 0;
  right: 0;
  bottom: 0;
  left: 0;
  z-index: 1;
  pointer-events: auto;
  content: "";
  background-color: rgba(0, 0, 0, 0); }

.text-monospace {
  font-family: SFMono-Regular, Menlo, Monaco, Consolas, "Liberation Mono", "Courier New", monospace !important; }

.text-justify {
  text-align: justify !important; }

.text-wrap {
  white-space: normal !important; }

.text-nowrap {
  white-space: nowrap !important; }

.text-truncate {
  overflow: hidden;
  text-overflow: ellipsis;
  white-space: nowrap; }

.text-left {
  text-align: left !important; }

.text-right {
  text-align: right !important; }

.text-center {
  text-align: center !important; }

@media (min-width: 576px) {
  .text-sm-left {
    text-align: left !important; }
  .text-sm-right {
    text-align: right !important; }
  .text-sm-center {
    text-align: center !important; } }

@media (min-width: 768px) {
  .text-md-left {
    text-align: left !important; }
  .text-md-right {
    text-align: right !important; }
  .text-md-center {
    text-align: center !important; } }

@media (min-width: 992px) {
  .text-lg-left {
    text-align: left !important; }
  .text-lg-right {
    text-align: right !important; }
  .text-lg-center {
    text-align: center !important; } }

@media (min-width: 1200px) {
  .text-xl-left {
    text-align: left !important; }
  .text-xl-right {
    text-align: right !important; }
  .text-xl-center {
    text-align: center !important; } }

.text-lowercase {
  text-transform: lowercase !important; }

.text-uppercase {
  text-transform: uppercase !important; }

.text-capitalize {
  text-transform: capitalize !important; }

.font-weight-light {
  font-weight: 300 !important; }

.font-weight-lighter {
  font-weight: lighter !important; }

.font-weight-normal {
  font-weight: 400 !important; }

.font-weight-bold {
  font-weight: 700 !important; }

.font-weight-bolder {
  font-weight: bolder !important; }

.font-italic {
  font-style: italic !important; }

.text-white {
  color: #fff !important; }

.text-primary {
  color: #007bff !important; }

a.text-primary:hover, a.text-primary:focus {
  color: #0056b3 !important; }

.text-secondary {
  color: #6c757d !important; }

a.text-secondary:hover, a.text-secondary:focus {
  color: #494f54 !important; }

.text-success {
  color: #28a745 !important; }

a.text-success:hover, a.text-success:focus {
  color: #19692c !important; }

.text-info {
  color: #17a2b8 !important; }

a.text-info:hover, a.text-info:focus {
  color: #0f6674 !important; }

.text-warning {
  color: #ffc107 !important; }

a.text-warning:hover, a.text-warning:focus {
  color: #ba8b00 !important; }

.text-danger {
  color: #dc3545 !important; }

a.text-danger:hover, a.text-danger:focus {
  color: #a71d2a !important; }

.text-light {
  color: #f8f9fa !important; }

a.text-light:hover, a.text-light:focus {
  color: #cbd3da !important; }

.text-dark {
  color: #343a40 !important; }

a.text-dark:hover, a.text-dark:focus {
  color: #121416 !important; }

.text-body {
  color: #212529 !important; }

.text-muted {
  color: #6c757d !important; }

.text-black-50 {
  color: rgba(0, 0, 0, 0.5) !important; }

.text-white-50 {
  color: rgba(255, 255, 255, 0.5) !important; }

.text-hide {
  font: 0/0 a;
  color: transparent;
  text-shadow: none;
  background-color: transparent;
  border: 0; }

.text-decoration-none {
  text-decoration: none !important; }

.text-break {
  word-wrap: break-word !important; }

.text-reset {
  color: inherit !important; }

.visible {
  visibility: visible !important; }

.invisible {
  visibility: hidden !important; }

body {
  margin: 0;
  overflow-x: hidden;
  font-family: 'Balsamiq Sans', sans-serif;
  font-size: 1.0416vw; }
  @media (max-width: 1199.98px) {
    body {
      font-size: 15px; } }
  .noscroll body,
  .swipebox-html body {
    overflow: hidden; }

*:focus {
  outline: none !important; }

a {
  text-decoration: none; }

ul li,
p,
span {
  letter-spacing: 0.55px;
  line-height: 1.5; }

h1,
p {
  font-style: normal;
  font-weight: 400; }

h1 {
  font-family: salvation, sans-serif;
  font-size: 1.25vw;
  margin: 0 0 2.291vw 0; }
  @media (max-width: 1199.98px) {
    h1 {
      font-size: 24px; } }
  @media (max-width: 767.98px) {
    h1 {
      font-size: 22px; } }

h2 {
  font-weight: 600; }

nav ul {
  margin: 0;
  padding: 0; }

.container-fluid, .container-sm, .container-md, .container-lg, .container-xl {
  max-width: 81.875vw; }
  @media (max-width: 1199.98px) {
    .container-fluid, .container-sm, .container-md, .container-lg, .container-xl {
      max-width: 91vw; } }

.box-border {
  border: 10px solid;
  border-image: url(../images/box_border.svg) 32 stretch;
  border-image-width: 24px; }

.button {
  border: 10px solid;
  border-image: url(../images/gomb.svg) 21 stretch;
  border-image-width: 24px;
  background-color: #d4d4d4;
  padding: 5px 10px 5px 10px;
  display: inline-block; }
  @media (max-width: 767.98px) {
    .button {
      padding-left: 10px; } }

#header {
  display: flex;
  justify-content: space-between;
  padding: 0 20px;
  position: fixed;
  z-index: 100;
  height: 80px;
  width: 100%;
  background-color: #c3c3c3;
  margin: 0;
  opacity: 0.95; }
  @media (max-width: 1600px) {
    #header {
      height: 5vw; } }
  @media (max-width: 991.98px) {
    #header {
      height: 80px; } }
  @media (max-width: 767.98px) {
    #header {
      height: 60px; } }
  #header .logo {
    display: flex;
    color: #000000;
    text-decoration: none; }
    #header .logo .icon {
      height: 50px;
      position: relative;
      top: 20%;
      margin-right: 2.4vw; }
      @media (max-width: 1600px) {
        #header .logo .icon {
          height: 3.125vw; } }
      @media (max-width: 991.98px) {
        #header .logo .icon {
          height: 50px; } }
      @media (max-width: 767.98px) {
        #header .logo .icon {
          display: none; } }
      @media (max-width: 400px) {
        #header .logo .icon {
          height: 27px;
          top: 28%; } }
    #header .logo p {
      font-size: 30px;
      margin-top: 22px;
      font-family: salvation, sans-serif;
      font-style: normal;
      font-weight: 400; }
      @media (max-width: 1600px) {
        #header .logo p {
          font-size: 1.875vw;
          margin-top: 1.375vw; } }
      @media (max-width: 991.98px) {
        #header .logo p {
          font-size: 38px;
          margin-top: 18px; } }
      @media (max-width: 767.98px) {
        #header .logo p {
          font-size: 24px;
          margin-top: 13.5px; } }
      @media (max-width: 400px) {
        #header .logo p {
          font-size: 20px;
          margin-top: 17.5px; } }
      @media (max-width: 350px) {
        #header .logo p {
          font-size: 17px;
          margin-top: 19.5px; } }
  #header #main-menu {
    display: flex;
    flex-grow: 1;
    justify-content: space-between;
    background-color: #c3c3c3; }
    @media (max-width: 991.98px) {
      #header #main-menu {
        width: 0;
        height: calc(100vh - 80px);
        overflow: hidden;
        position: fixed;
        flex-direction: column;
        top: 80px;
        left: 0;
        bottom: 0;
        transition: width 1s ease-in-out; }
        .open-menu #header #main-menu {
          width: 50%; } }
    @media (max-width: 767.98px) {
      #header #main-menu {
        top: 60px;
        height: calc(100vh - 60px); }
        .open-menu #header #main-menu {
          width: 100%; } }
    @media (max-width: 991.98px) {
      #header #main-menu .icons {
        justify-content: space-between;
        padding: 35px; } }
    @media (max-width: 991.98px) {
      #header #main-menu .icons {
        padding: 25px; } }
  #header .plate-thing {
    flex-grow: 1;
    background: url(../images/plate_thing.svg) no-repeat center center;
    background-size: contain;
    margin-left: 20px;
    margin-right: 20px; }
  #header .links {
    margin: 0 auto; }
    @media (max-width: 991.98px) {
      #header .links {
        padding-top: 15px; } }
    #header .links ul {
      display: flex;
      height: 100%;
      font-family: salvation, sans-serif;
      font-style: normal;
      font-weight: 400;
      align-items: center;
      list-style-type: none;
      text-decoration: none; }
      @media (max-width: 991.98px) {
        #header .links ul {
          flex-direction: column; } }
      #header .links ul li {
        padding-right: 25px; }
        @media (max-width: 1600px) {
          #header .links ul li {
            padding-right: 1.5625vw; } }
        #header .links ul li a {
          position: relative;
          top: 5px;
          height: 100%;
          text-decoration: none;
          color: #000;
          font-size: 1.25vw; }
          @media (max-width: 1600px) {
            #header .links ul li a {
              top: 0.3125vw;
              font-size: 1.5vw; } }
          @media (max-width: 991.98px) {
            #header .links ul li a {
              display: block;
              font-size: 28px;
              margin-bottom: 25px; } }
          #header .links ul li a:hover {
            color: #d2691e; }
  #header .icons {
    display: flex;
    align-items: center; }
    #header .icons .icons-item {
      position: relative;
      height: 32px;
      margin-right: 15px; }
      @media (max-width: 1600px) {
        #header .icons .icons-item {
          height: 2vw;
          margin-right: 0.9375vw; } }
      @media (max-width: 991.98px) {
        #header .icons .icons-item {
          height: 45px;
          width: 45px; } }
      @media (max-width: 767.98px) {
        #header .icons .icons-item {
          height: 43.75px;
          width: 43.75px; } }
    #header .icons .cart-icon {
      position: relative;
      display: inline-block;
      height: 32px;
      width: 40px; }
      @media (max-width: 1600px) {
        #header .icons .cart-icon {
          height: 2vw;
          width: 2.3vw; } }
      @media (max-width: 991.98px) {
        #header .icons .cart-icon {
          height: 43.75px;
          width: 43.75px; } }
      @media (max-width: 767.98px) {
        #header .icons .cart-icon {
          height: 37px;
          width: 37px; } }
      #header .icons .cart-icon .dot {
        width: 15px;
        height: 15px;
        border-radius: 100%;
        font-size: 10px;
        color: #fff;
        text-align: center;
        background: #000;
        display: inline-grid;
        position: absolute;
        right: -7px;
        top: -4px;
        align-items: center; }
  #header .hamburger-icon {
    align-items: center; }
    #header .hamburger-icon a {
      display: block;
      height: 27px; }
      @media (max-width: 767.98px) {
        #header .hamburger-icon a {
          height: 20.25px; } }
    #header .hamburger-icon img {
      height: 27px;
      width: auto;
      display: block; }
      @media (max-width: 767.98px) {
        #header .hamburger-icon img {
          height: 20.25px; } }
  #header .icons-item {
    min-width: 2vw;
    background-position: center !important; }
  #header #facebook-icon {
    background: url(/assets/images/facebook.svg) no-repeat;
    -webkit-transition: background .3s;
    transition: background .3s; }
  #header #facebook-icon:hover {
    background: url(/assets/images/facebook_hover.svg) no-repeat; }
  #header #instagram-icon {
    background: url(/assets/images/instagram.svg) no-repeat;
    -webkit-transition: background .3s;
    transition: background .3s; }
  #header #instagram-icon:hover {
    background: url(/assets/images/instagram_hover.svg) no-repeat; }
  #header #telephone-icon {
    background: url(/assets/images/telefon.svg) no-repeat;
    -webkit-transition: background .3s;
    transition: background .3s; }
  #header #telephone-icon:hover {
    background: url(/assets/images/telefon_hover.svg) no-repeat; }
  #header #email-icon {
    background: url(/assets/images/email_uj.svg) no-repeat;
    -webkit-transition: background .3s;
    transition: background .3s; }
  #header #email-icon:hover {
    background: url(/assets/images/email_uj_hover.svg) no-repeat; }
  #header .cart-icon {
    background: url(/assets/images/cart.svg) no-repeat;
    -webkit-transition: background .3s;
    transition: background .3s; }
  #header .cart-icon:hover {
    background: url(/assets/images/cart_hover.svg) no-repeat; }

#footer {
  padding-top: 10px;
  background-color: #808080; }
  #footer .credits {
    text-align: center; }
    #footer .credits a {
      color: black;
      padding-right: 10px;
      font-size: 15px; }
      #footer .credits a:hover {
        color: #d2691e; }
  #footer .footer-title,
  #footer .footer-xfb {
    margin-bottom: 0;
    text-align: center; }
  #footer .footer-title {
    font-size: 14px; }
  #footer .footer-xfb {
    font-size: 12px; }
    #footer .footer-xfb a {
      color: #000000;
      text-decoration: none; }
      #footer .footer-xfb a:hover {
        color: #d2691e; }

@media (max-width: 991.98px) {
  .block h1 {
    text-align: center; } }

.block h1.title-right {
  text-align: right; }
  @media (max-width: 991.98px) {
    .block h1.title-right {
      text-align: center; } }

.block h1:after {
  display: block;
  width: calc(100% + 32px);
  height: 6px;
  margin-left: -16px;
  margin-right: -16px;
  content: "";
  background: url(../images/line.svg) no-repeat center center;
  background-size: 100% auto; }
  @media (max-width: 767.98px) {
    .block h1:after {
      background-image: url(../images/mobile_title_line.svg);
      background-size: 220px auto; } }

@media (orientation: portrait) and (max-width: 991.98px) {
  #main-carousel {
    padding-top: 80px; } }

@media (orientation: portrait) and (max-width: 767.98px) {
  #main-carousel {
    padding-top: 60px; } }

#main-carousel .item {
  padding-bottom: 100vh;
  background: no-repeat 50%;
  background-size: cover;
  min-height: 800px; }
  @media (max-width: 1199.98px) {
    #main-carousel .item {
      min-height: 600px; } }
  @media (max-width: 991.98px) {
    #main-carousel .item {
      min-height: 500px; } }
  @media (max-width: 767.98px) {
    #main-carousel .item {
      min-height: 300px; } }
  @media (orientation: portrait) {
    #main-carousel .item {
      padding-bottom: 50%;
      min-height: auto; } }

#main-carousel .owl-loaded .owl-drag {
  bottom: 150px; }

#main-carousel .owl-nav {
  display: flex;
  -ms-flex-pack: justify;
  justify-content: space-between;
  margin: 0 50px;
  color: #000; }
  @media (max-width: 991.98px) {
    #main-carousel .owl-nav {
      display: none; } }

#main-carousel .owl-nav button.owl-next,
#main-carousel .owl-nav button.owl-prev {
  position: absolute;
  top: 80px;
  bottom: 0;
  margin: auto;
  height: 50px;
  width: 50px; }
  @media (max-width: 1600px) {
    #main-carousel .owl-nav button.owl-next,
    #main-carousel .owl-nav button.owl-prev {
      top: 5vw;
      height: 3.125vw;
      width: 3.125vw; } }

#main-carousel .owl-nav button.owl-prev {
  background: url(/assets/images/arrow_left.svg) no-repeat;
  left: 20px; }
  #main-carousel .owl-nav button.owl-prev:hover {
    background-image: url(/assets/images/arrow_left_hover.svg); }

#main-carousel .owl-nav button.owl-next {
  background: url(/assets/images/arrow_right.svg) no-repeat;
  right: 20px; }
  #main-carousel .owl-nav button.owl-next:hover {
    background-image: url(/assets/images/arrow_right_hover.svg); }

#main-carousel .owl-dots {
  position: absolute;
  bottom: 30px;
  left: 0;
  right: 0; }
  @media (max-width: 1600px) {
    #main-carousel .owl-dots {
      bottom: 1.875vw; } }
  #main-carousel .owl-dots .owl-dot {
    margin: 0 60px; }
    @media (max-width: 1600px) {
      #main-carousel .owl-dots .owl-dot {
        margin: 0 3.75vw; } }

#bio {
  padding-top: 100px;
  background: #d4d4d4 url(/assets/images/bio_r_down_corner.svg) no-repeat bottom 0 right 0;
  background-size: 45vw; }
  @media (max-width: 991.98px) {
    #bio {
      padding-top: 35px; } }
  @media (max-width: 767.98px) {
    #bio {
      background-size: 95vw;
      padding-bottom: 30vw;
      padding-top: 30px; } }
  #bio h2 {
    font-style: normal;
    font-size: 20px; }
  #bio ul {
    list-style-type: none;
    font-weight: 400;
    line-height: 2; }
  #bio .bio-title {
    margin-top: -130px;
    padding-top: 130px; }
  #bio .bio-text {
    padding-bottom: 5vw; }
  #bio .bio-break {
    background: url(/assets/images/plate_thing.svg) no-repeat top right 5%;
    background-size: 55%; }
  #bio .bio-pic {
    text-align: right; }
    @media (max-width: 991.98px) {
      #bio .bio-pic {
        text-align: center; } }
    #bio .bio-pic .profile-pic {
      max-height: 25vw; }
      @media (max-width: 991.98px) {
        #bio .bio-pic .profile-pic {
          max-height: none;
          width: 100%; } }
      @media (max-width: 767.98px) {
        #bio .bio-pic .profile-pic {
          width: 80%; } }

#products {
  background-color: gray;
  background-image: url(../images/products_line.svg), url(../images/munkaim_hatter_top.svg);
  background-repeat: no-repeat;
  background-position: top, bottom;
  padding-bottom: 14vw;
  padding-left: 0;
  /* -------------------------------- 
      
      Step content - basic style
      
      -------------------------------- */
  /* -------------------------------- 
      
      Models Step - style
      
      -------------------------------- */
  /* -------------------------------- 
      
      Colors Step - style
      
      -------------------------------- */
  /* -------------------------------- 
      
      Summary Step - style
      
      -------------------------------- */
  /* -------------------------------- 
      
      Builder top navigation
      
      -------------------------------- */
  /* Tooltip container */
  /* Tooltip text */
  /* Show the tooltip text when you mouse over the tooltip container */ }
  #products span {
    font-size: .9rem;
    font-weight: 500; }
  #products h1 {
    padding-top: 160px;
    margin-top: -100px; }
  #products .cd-builder-steps {
    position: relative;
    overflow: hidden;
    text-align: center; }
  #products .cd-builder-steps > ul {
    height: 100%;
    overflow: hidden;
    text-align: center;
    padding: 0;
    margin: auto;
    padding-inline-start: 0 !important; }
  #products .cd-builder-steps li {
    list-style: none;
    display: inline-block;
    text-align: center;
    width: auto; }
  #products .cd-builder-steps .builder-step {
    position: absolute;
    z-index: 1;
    top: 0;
    left: 0;
    width: 100%;
    height: 100%;
    overflow-y: auto;
    overflow-x: hidden;
    -webkit-overflow-scrolling: touch;
    visibility: hidden;
    -webkit-transition: visibility .5s;
    transition: visibility .5s; }
  #products .cd-builder-steps .builder-step.active {
    position: relative;
    z-index: 2;
    visibility: visible;
    -webkit-transition: visibility .7s;
    transition: visibility .7s; }
  #products .cd-builder-steps .builder-step.first-load {
    /* hide content uploaded via Ajax */
    display: none; }
  #products .cd-step-content {
    opacity: 0;
    /* this is the animation of a section moving right (go back to a prev step) - selection already made */
    -webkit-animation: cd-center-to-right .5s 0s backwards;
    animation: cd-center-to-right .5s 0s backwards; }
  #products .cd-step-content .options-list > li {
    /* basic style for list of options */
    background: gray;
    margin: .5em;
    border: 10px solid;
    border-image: url(../images/keret.svg) 32 stretch;
    border-image-width: 24px;
    cursor: pointer;
    -webkit-transition: border-image .3s;
    transition: border-image .3s; }
  #products .cd-step-content .options-list > li.selected {
    border-color: #d2691e; }
  #products .cd-step-content .options-list > li:hover {
    border-image: url(../images/keret_hover.svg) 32 stretch;
    border-image-width: 24px; }
  #products .active .cd-step-content {
    /* this is the animation of the selected step */
    opacity: 1;
    -webkit-transform: translateX(0);
    -ms-transform: translateX(0);
    transform: translateX(0);
    -webkit-animation: cd-right-to-center .5s .2s backwards;
    animation: cd-right-to-center .5s .2s backwards; }
  #products .move-left .cd-step-content {
    /* this is the animation of a section moving left - selection already made */
    -webkit-animation: cd-center-to-left .5s 0s backwards;
    animation: cd-center-to-left .5s 0s backwards; }
  #products .active.back .cd-step-content {
    /* this is the animation of the selected step - moving down*/
    -webkit-animation: cd-left-to-center .5s .2s backwards;
    animation: cd-left-to-center .5s .2s backwards; }
  #products .cd-builder-steps .models-list {
    padding-inline-start: inherit;
    text-align: center; }
  #products .cd-builder-steps .models-list > li {
    padding: 0 0 1% 0; }
  #products .cd-builder-steps .models-list .name {
    display: block;
    margin-top: .2em;
    font-weight: bold; }
  #products .cd-builder-steps .models-list img {
    mask-image: url(../images/img_clip.svg);
    mask-repeat: no-repeat;
    mask-position: center;
    object-fit: cover;
    display: block;
    width: 81%;
    margin: 4% auto 3%;
    height: auto; }
  #products .cd-builder-steps .models-list .price {
    display: block;
    font-weight: bold; }
  #products .cd-product-customizer {
    background: gray;
    margin: .5em;
    padding: .5em;
    text-align: center;
    display: inline-grid;
    border: 10px solid;
    border-image: url(../images/keret.svg) 32 stretch;
    border-image-width: 24px;
    min-height: 320px; }
  #products .cd-product-customizer li {
    position: relative;
    margin: 8px 0px; }
  #products .cd-product-customizer .selected a {
    border: 2px solid #d2691e;
    width: 64px;
    height: 64px;
    margin: -5px; }
  #products .cd-product-customizer a {
    /* replace text with bg color */
    display: inline-block;
    overflow: hidden;
    text-indent: 100%;
    color: transparent;
    white-space: nowrap;
    width: 54px;
    height: 54px;
    border-radius: 50%;
    border: 2px solid #ffffff;
    background-size: cover;
    transition: border .3s; }
  #products .cd-product-customizer ul {
    columns: 3;
    margin-top: 8px;
    padding-inline-start: initial;
    max-width: 230px; }
  #products [data-wool-id="fhr"] {
    /* used to give background color to Color options */
    background-image: url(../images/wool_color_id_FHR.jpg);
    background-position: center; }
  #products [data-wool-id="fkt"] {
    background-image: url(../images/wool_color_id_FKT.jpg);
    background-position: center; }
  #products [data-wool-id="zld"] {
    background-image: url(../images/wool_color_id_ZLD.jpg);
    background-position: center; }
  #products [data-wool-id="rel"] {
    background-image: url(../images/wool_color_id_REL.jpg);
    background-position: center; }
  #products [data-wool-id="kek"] {
    background-image: url(../images/wool_color_id_KEK.jpg);
    background-position: center; }
  #products [data-wool-id="brn"] {
    background-image: url(../images/wool_color_id_BRN.jpg);
    background-position: center; }
  #products [data-wool-id="prs"] {
    background-image: url(../images/wool_color_id_PRS.jpg);
    background-position: center; }
  #products [data-wool-id="srg"] {
    background-image: url(../images/wool_color_id_SRG.jpg);
    background-position: center; }
  #products [data-wool-id="crm"] {
    background-image: url(../images/wool_color_id_CRM.jpg);
    background-position: center; }
  #products [data-glaze-id="fhr"] {
    /* used to give background color to Color options */
    background-image: url(../images/glaze_color_id_FHR.jpg);
    background-position: center; }
  #products [data-glaze-id="fkt"] {
    background-image: url(../images/glaze_color_id_FKT.jpg);
    background-position: center; }
  #products [data-glaze-id="zld"] {
    background-image: url(../images/glaze_color_id_ZLD.jpg);
    background-position: center; }
  #products [data-glaze-id="lla"] {
    background-image: url(../images/glaze_color_id_LLA.jpg);
    background-position: center; }
  #products [data-glaze-id="kek"] {
    background-image: url(../images/glaze_color_id_KEK.jpg);
    background-position: center; }
  #products [data-glaze-id="nrc"] {
    background-image: url(../images/glaze_color_id_NRC.jpg);
    background-position: center; }
  #products [data-glaze-id="prs"] {
    background-image: url(../images/glaze_color_id_PRS.jpg);
    background-position: center; }
  #products [data-glaze-id="srg"] {
    background-image: url(../images/glaze_color_id_SRG.jpg);
    background-position: center; }
  #products [data-glaze-id="zlb"] {
    background-image: url(../images/glaze_color_id_ZLB.jpg);
    background-position: center; }
  #products [data-pearl-id="srg"] {
    background-image: url(../images/pearl_type_id_SRG.jpg);
    background-position: center; }
  #products [data-pearl-id="kek"] {
    background-image: url(../images/pearl_type_id_KEK.jpg);
    background-position: center; }
  #products [data-pearl-id="vgy"] {
    background-image: url(../images/pearl_type_id_VGY.jpg);
    background-position: center; }
  #products [data-pearl-id="fkt"] {
    background-image: url(../images/pearl_type_id_FKT.jpg);
    background-position: center; }
  #products [data-pearl-id="prs"] {
    background-image: url(../images/pearl_type_id_PRS.jpg);
    background-position: center; }
  #products [data-pearl-id="atl"] {
    background-image: url(../images/pearl_type_id_ATL.jpg);
    background-position: center; }
  #products [data-glass-id="brn"] {
    background-image: url(../images/glass_color_id_BRN.jpg);
    background-position: center; }
  #products [data-glass-id="fhr"] {
    background-image: url(../images/glass_color_id_FHR.jpg);
    background-position: center; }
  #products [data-glass-id="kek"] {
    background-image: url(../images/glass_color_id_KEK.jpg);
    background-position: center; }
  #products [data-glass-id="mlf"] {
    background-image: url(../images/glass_color_id_MLF.jpg);
    background-position: center; }
  #products [data-glass-id="vgy"] {
    background-image: url(../images/glass_color_id_VGY.jpg);
    background-position: center; }
  #products [data-glass-id="zld"] {
    background-image: url(../images/glass_color_id_ZLD.jpg);
    background-position: center; }
  #products .cd-builder-steps .summary-image img {
    mask-image: url(../images/img_clip.svg);
    mask-repeat: no-repeat;
    mask-position: center;
    object-fit: cover;
    display: block;
    margin: 4% auto 3%;
    height: auto;
    width: 100%;
    max-width: 264px; }
  #products .cd-builder-steps .summary-box {
    background-color: gray;
    border: 10px solid;
    border-image: url(../images/keret.svg) 64 stretch;
    border-image-width: 32px;
    padding: 16px;
    margin: 0 10px; }
  #products .cd-builder-steps .summary-list .product-preview {
    display: block;
    max-width: 700px;
    width: 100%;
    margin-bottom: 2em; }
  #products .cd-builder-steps .summary-list h3 {
    font-size: 1.1rem;
    font-weight: bold;
    margin: .4em 0; }
  #products .cd-builder-steps .summary-list p {
    color: #fff;
    line-height: 1.6; }
  #products .cd-builder-steps .summary-list .summary-color::after {
    clear: both;
    content: "";
    display: block; }
  #products .cd-builder-steps .summary-list .color-swatch {
    display: inline-block;
    height: 40px;
    width: 40px;
    border: 2px solid #ffffff;
    border-radius: 50%;
    background-size: cover; }
  #products .cd-builder-steps .summary-list .color-label {
    display: inline-block;
    margin-top: 11px;
    margin-left: .5em; }
  #products .cd-builder-steps .summary-list {
    padding-inline-start: inherit;
    display: inline-grid; }
  #products .cd-builder-steps .summary-list div {
    align-items: center;
    display: inline-flex;
    height: 54px; }
  #products .cd-builder-steps .summary-list span {
    margin: 0px 16px; }
  #products .cd-builder-steps .summary-list > li {
    margin: 4px;
    padding: 12px;
    border: 1px solid;
    display: inline-block;
    text-align: left;
    border-image: url(../images/gomb.svg) 32 stretch;
    border-image-width: 24px;
    display: flex;
    justify-content: space-between;
    align-items: center; }
  #products .cd-builder-steps .summary-list h2::before {
    margin: 0 auto .6em; }
  #products .cd-builder-steps .summary-list h3 {
    text-align: center; }
  #products .cd-builder-steps .summary-list .summary-color {
    display: inline-block; }
  #products .cd-builder-steps .summary-list .color-swatch {
    height: 50px;
    width: 50px;
    min-height: 50px;
    min-width: 50px; }
  #products .cd-builder-main-nav {
    text-align: center;
    margin-bottom: 16px; }
  #products .cd-builder-main-nav li {
    display: inline-block; }
  #products .cd-builder-main-nav a {
    display: block;
    margin-right: 16px;
    color: black;
    font-weight: bold;
    text-transform: uppercase;
    border-bottom: 2px solid transparent;
    -webkit-transition: color .2s, border-color .2s;
    transition: color .2s, border-color .2s; }
  #products .cd-builder-main-nav a:hover {
    color: #d2691e;
    text-decoration: none; }
  #products .cd-builder-main-nav .active a {
    border-color: #000;
    color: #000; }
  #products .cd-builder-main-nav .active a:hover {
    color: #d2691e; }
  #products li.inactive {
    visibility: hidden;
    display: none; }
  #products li.active {
    visibility: visible;
    display: inline-block; }
  #products .minus {
    background: url(../images/minus.svg) no-repeat center center;
    width: 32px;
    height: 32px;
    border: 0;
    -webkit-transition: background .3s;
    transition: background .3s; }
  #products .minus:hover {
    background: url(../images/minus_hover.svg) no-repeat center center; }
  #products .plus {
    background: url(../images/plus.svg) no-repeat center center;
    width: 32px;
    height: 32px;
    border: 0;
    -webkit-transition: background .3s;
    transition: background .3s; }
  #products .plus:hover {
    background: url(../images/plus_hover.svg) no-repeat center center; }
  #products .cart-quantity-btn {
    display: flex;
    justify-content: center;
    align-items: center; }
  #products .cart-quantity-btn input {
    width: 30px;
    min-width: 30px;
    margin: 0;
    padding: 0;
    appearance: none;
    text-align: center;
    background-color: gray;
    border: hidden;
    font-size: 24px; }
  #products .button-style {
    margin: 4px;
    padding: 8px 20px;
    border: none;
    text-align: center;
    font-size: 24px;
    background: url(../images/gomb.svg) no-repeat;
    -webkit-transition: background .3s;
    transition: background .3s; }
  #products .button-style:hover {
    color: white;
    background: url(../images/gomb_hover.svg) no-repeat; }

@-webkit-keyframes cd-right-to-center {
  0% {
    opacity: 0;
    -webkit-transform: translateX(30px);
    transform: translateX(30px); }
  100% {
    opacity: 1;
    -webkit-transform: translateX(0);
    transform: translateX(0); } }

@keyframes cd-right-to-center {
  0% {
    opacity: 0;
    -webkit-transform: translateX(30px);
    transform: translateX(30px); }
  100% {
    opacity: 1;
    -webkit-transform: translateX(0);
    transform: translateX(0); } }

@-webkit-keyframes cd-left-to-center {
  0% {
    opacity: 0;
    -webkit-transform: translateX(-30px);
    transform: translateX(-30px); }
  100% {
    opacity: 1;
    -webkit-transform: translateX(0);
    transform: translateX(0); } }

@keyframes cd-left-to-center {
  0% {
    opacity: 0;
    -webkit-transform: translateX(-30px);
    transform: translateX(-30px); }
  100% {
    opacity: 1;
    -webkit-transform: translateX(0);
    transform: translateX(0); } }

@-webkit-keyframes cd-center-to-left {
  0% {
    opacity: 1;
    -webkit-transform: translateX(0);
    transform: translateX(0); }
  100% {
    opacity: 0;
    -webkit-transform: translateX(-30px);
    transform: translateX(-30px); } }

@keyframes cd-center-to-left {
  0% {
    opacity: 1;
    -webkit-transform: translateX(0);
    transform: translateX(0); }
  100% {
    opacity: 0;
    -webkit-transform: translateX(-30px);
    transform: translateX(-30px); } }

@-webkit-keyframes cd-center-to-right {
  0% {
    opacity: 1;
    -webkit-transform: translateX(0);
    transform: translateX(0); }
  100% {
    opacity: 0;
    -webkit-transform: translateX(30px);
    transform: translateX(30px); } }

@keyframes cd-center-to-right {
  0% {
    opacity: 1;
    -webkit-transform: translateX(0);
    transform: translateX(0); }
  100% {
    opacity: 0;
    -webkit-transform: translateX(30px);
    transform: translateX(30px); } }
  #products .open-hd-image {
    width: 20px;
    height: 20px;
    background: url(/assets/images/search-plus-solid.svg) no-repeat 50%;
    background-size: auto 100%;
    display: block;
    position: absolute;
    right: 5px;
    bottom: 5px;
    transition: all .2s ease-in-out;
    transform: scale(1); }
    #products .open-hd-image:hover {
      transform: scale(1.2); }
  #products .tooltip {
    position: relative;
    display: inline-block; }
  #products .tooltip .tooltiptext {
    visibility: hidden;
    width: 120px;
    background-color: black;
    color: #fff;
    text-align: center;
    padding: 5px 0;
    border-radius: 6px;
    left: 110%;
    top: 15%;
    /* Position the tooltip text - see examples below! */
    position: absolute;
    z-index: 1; }
  #products .tooltip:hover .tooltiptext {
    visibility: visible; }
  #products .tooltip .tooltiptext::after {
    content: " ";
    position: absolute;
    top: 50%;
    right: 100%;
    /* To the left of the tooltip */
    margin-top: -5px;
    border-width: 5px;
    border-style: solid;
    border-color: transparent black transparent transparent; }

#works {
  position: relative;
  background-color: #bcbcbc;
  margin-top: -1px; }
  #works h1 {
    margin: 0 0 1.145vw 0.691vw; }
    @media (max-width: 767.98px) {
      #works h1 {
        margin-top: 20px; } }
    #works h1:after {
      background-image: url(/assets/images/line2.svg); }
  #works .works-title {
    margin-top: calc(-6.25vw - 200px);
    padding-top: 200px; }
  #works #works-link .works-links {
    margin-top: -1.0416vw;
    display: flex;
    justify-content: space-between;
    padding-bottom: 10px;
    background: #BCBCBC; }
    #works #works-link .works-links ul li {
      padding-right: 5px; }
    #works #works-link .works-links a {
      position: relative;
      top: 90%;
      height: 100%;
      text-decoration: none;
      padding: 5px 10px 5px 10px;
      color: #000;
      font-style: normal; }
    #works #works-link .works-links a:hover {
      color: #d2691e; }
    #works #works-link .works-links .active {
      background-color: #808080;
      border: 1px solid #808080;
      border-radius: 4px; }
  #works .works-links .item {
    display: block;
    position: relative;
    cursor: pointer;
    padding-bottom: 58%;
    background: no-repeat 50%;
    background-size: cover;
    mask-image: url(/assets/images/img_clip_rectangle.svg);
    mask-repeat: no-repeat;
    mask-position: center;
    object-fit: cover;
    margin: 15px; }
    #works .works-links .item .title {
      position: absolute;
      bottom: 0;
      left: 0;
      right: 0;
      padding: 10px;
      background: rgba(0, 0, 0, 0.75);
      color: #ffff;
      text-align: center; }
  #works .galleries {
    padding-bottom: 1px; }
  #works .gallery {
    display: none;
    width: 100%;
    max-width: 800px;
    margin: 0 auto; }
    #works .gallery .gallery-items {
      display: flex;
      flex-wrap: wrap;
      margin-left: -10px;
      margin-right: -10px; }
    #works .gallery .gallery-item {
      box-sizing: border-box;
      width: 20%;
      padding-left: 10px;
      padding-right: 10px;
      margin-bottom: 20px; }
      @media (max-width: 767.98px) {
        #works .gallery .gallery-item {
          width: 25%; } }
      @media (max-width: 575.98px) {
        #works .gallery .gallery-item {
          width: 33.333%; } }
    #works .gallery .gallery-item a {
      display: block;
      width: 100%;
      padding-bottom: 100%;
      background: no-repeat center center / cover;
      mask-image: url(/assets/images/img_clip.svg);
      mask-repeat: no-repeat;
      mask-position: center;
      object-fit: cover; }

@media (max-width: 575.98px) {
  #works-carousel {
    display: none; } }

#works-carousel .item {
  cursor: pointer;
  padding-bottom: 65%;
  background: no-repeat 50%;
  background-size: cover;
  mask-image: url(/assets/images/img_clip_rectangle.svg);
  mask-repeat: no-repeat;
  mask-position: center;
  object-fit: cover; }
  #works-carousel .item .title {
    position: absolute;
    bottom: 0;
    left: 0;
    right: 0;
    padding: 15px;
    background: rgba(0, 0, 0, 0.75);
    color: #ffff;
    text-align: center; }

#works-carousel .owl-loaded .owl-drag {
  bottom: 150px; }

#works-carousel .owl-nav {
  display: flex;
  justify-content: space-between;
  color: #000; }
  @media (max-width: 1199.98px) {
    #works-carousel .owl-nav {
      display: none; } }

#works-carousel .owl-nav button.owl-next,
#works-carousel .owl-nav button.owl-prev {
  position: absolute;
  top: 0;
  bottom: 0;
  margin: auto;
  height: 50px;
  width: 50px; }

#works-carousel .owl-nav button.owl-prev {
  left: -86px;
  background: url(/assets/images/arrow_left.svg) no-repeat; }

#works-carousel .owl-nav button.owl-next {
  right: -86px;
  background: url(/assets/images/arrow_right.svg) no-repeat; }

#works-carousel .owl-nav button.owl-prev:hover {
  background: url(/assets/images/arrow_left.svg) no-repeat; }

#works-carousel .owl-nav button.owl-next:hover {
  background: url(/assets/images/arrow_right.svg) no-repeat; }

#works-carousel .owl-dots {
  position: relative; }

#works-carousel .owl-dots .owl-dot {
  position: absolute;
  right: 0;
  left: 0;
  margin: auto; }

#workshop {
  height: auto;
  background: gray url(/assets/images/munkaim_hatter_bottom.svg) no-repeat top;
  background-size: 100% auto;
  padding-top: 10vw; }
  #workshop h1 {
    padding-top: 60px; }
  #workshop h3 {
    font-size: 24px;
    font-weight: bold; }
  #workshop h4 {
    font-size: 1.041vw;
    font-weight: bold;
    margin-bottom: 1.406vw; }
    @media (max-width: 1199.98px) {
      #workshop h4 {
        font-size: 15px; } }
  #workshop .workshop-title {
    margin-top: -70px;
    padding-top: 70px; }
  #workshop .ws-type-row {
    margin-top: 1.6927vw; }
  #workshop .ws-type {
    text-align: center; }
  #workshop .ws-info {
    margin-bottom: 1.5625vw; }
  #workshop .ws-pic img {
    width: 100%; }
  #workshop .ws-pic-img {
    width: 100%;
    padding-bottom: 65%;
    background-size: cover;
    background-position: center center;
    background-repeat: no-repeat;
    margin-bottom: 20px;
    mask-image: url(/assets/images/img_clip_rectangle.svg);
    mask-repeat: no-repeat;
    mask-position: center;
    object-fit: cover;
    display: block; }
  #workshop .ws-dates {
    position: relative;
    padding: 15px;
    display: flex;
    flex-direction: column; }
    @media (max-width: 350px) {
      #workshop .ws-dates {
        padding: 10px; }
        #workshop .ws-dates [class*="col"] {
          margin-left: 0;
          flex: 0 0 100%;
          max-width: 100%; } }
    #workshop .ws-dates a {
      text-decoration: none;
      color: black; }
    #workshop .ws-dates a:hover {
      color: #d2691e; }
    #workshop .ws-dates h3 {
      margin: 0;
      padding-right: 50px;
      font-size: 20px; }
  #workshop .workshop_table {
    width: 100%; }
    #workshop .workshop_table td {
      padding-bottom: 1.145vw; }
      #workshop .workshop_table td .workshop-item {
        display: flex;
        justify-content: space-around;
        align-items: center; }
        #workshop .workshop_table td .workshop-item span {
          margin: 0 15px;
          white-space: nowrap; }
        @media (max-width: 767.98px) {
          #workshop .workshop_table td .workshop-item {
            flex-direction: column;
            justify-content: center;
            align-items: flex-start; } }
      #workshop .workshop_table td:nth-child(2) {
        text-align: right;
        padding-right: 15px; }
  #workshop .location {
    display: flex;
    align-items: baseline; }

#contact {
  min-height: auto;
  background: #bcbcbc url(/assets/images/contact_bg.png) no-repeat 0;
  background-size: 60vw;
  padding: 0 0 9vw 0; }
  #contact h1 {
    padding-top: 60px; }
  #contact .spoon-graph {
    display: flex;
    margin-bottom: 21vw; }
    #contact .spoon-graph:after {
      background: url(/assets/images/spoonfriend.svg) no-repeat center center;
      content: "";
      background-size: contain;
      width: 7.083vw;
      height: 20.83vw;
      position: absolute;
      top: 2.5vw;
      left: 1.5vw; }
  #contact .text-row {
    padding-top: 0.52vw; }
    #contact .text-row [class^="col"] {
      text-align: right; }
      @media (max-width: 767.98px) {
        #contact .text-row [class^="col"] {
          text-align: center; } }
    #contact .text-row .text {
      display: inline-flex;
      flex-flow: column; }
  #contact .text-elements {
    display: flex;
    flex-grow: 1;
    align-items: center;
    padding: 8px; }
  #contact .text-elements img {
    height: 32px;
    margin-left: 0;
    align-self: center;
    margin-right: 12px; }
  #contact .text-elements h2 {
    align-self: center;
    padding-left: 15px; }
  #contact p {
    text-align: center;
    line-height: 1.2;
    font-size: 16px; }
  #contact h5 {
    font-size: 1.0416vw;
    margin: 0; }
    @media (max-width: 1199.98px) {
      #contact h5 {
        font-size: 15px; } }
  #contact a {
    text-decoration: none;
    color: #343a40; }
    #contact a:hover {
      color: #d2691e; }

#cart {
  padding-top: 140px;
  min-height: 100vh;
  background-color: #d4d4d4;
  padding-bottom: 15vw; }
  @media (max-width: 767.98px) {
    #cart {
      padding-bottom: 0; } }
  #cart:after {
    content: "";
    display: block;
    position: fixed;
    background: #d4d4d4 url(/assets/images/bio_r_down_corner.svg) no-repeat bottom right;
    background-size: 38vw;
    bottom: 0;
    right: 0;
    width: 100vw;
    height: 20vw;
    z-index: 1; }
    @media (max-width: 767.98px) {
      #cart:after {
        height: 26vw;
        background-size: 92vw;
        position: static;
        margin-top: 4vw; } }
  #cart .container-fluid, #cart .container-sm, #cart .container-md, #cart .container-lg, #cart .container-xl {
    position: relative;
    z-index: 2; }
  #cart a {
    color: #000000;
    text-decoration: none; }
  #cart h1 {
    margin: 0; }
  #cart input[type=number]::-webkit-inner-spin-button,
  #cart input[type=number]::-webkit-outer-spin-button {
    -webkit-appearance: none;
    margin: 0; }
  #cart .cart-body {
    position: relative;
    padding-bottom: 10vw; }
  #cart table, #cart th, #cart td {
    padding: 15px; }
    @media (max-width: 767.98px) {
      #cart table, #cart th, #cart td {
        padding: 5px 0; } }
  #cart table {
    width: 100%;
    border-spacing: 0; }
    @media (max-width: 767.98px) {
      #cart table {
        display: flex;
        flex-direction: column;
        padding: 20px 0 0 0; } }
  @media (max-width: 767.98px) {
    #cart tbody tr {
      display: flex;
      flex-direction: column;
      position: relative;
      margin-bottom: 10px;
      padding-bottom: 10px; }
      #cart tbody tr:after {
        position: absolute;
        bottom: 0;
        left: 0;
        right: 0;
        order: 10;
        content: '';
        display: block;
        width: 50%;
        height: 2px;
        margin: 0 auto;
        background: url(../images/mobile_title_line.svg) no-repeat;
        background-size: contain; } }
  @media (max-width: 767.98px) {
    #cart tfoot tr {
      display: flex;
      justify-content: center;
      padding-bottom: 10px; }
      #cart tfoot tr:last-child {
        justify-content: space-between;
        padding-bottom: 0; } }
  @media (max-width: 767.98px) {
    #cart thead {
      display: none; } }
  #cart th {
    font-weight: 700; }
    #cart th:nth-child(3), #cart th:nth-child(4) {
      text-align: center; }
  #cart td {
    vertical-align: middle; }
    #cart td:last-child {
      white-space: nowrap; }
  #cart .cart-product {
    text-align: left;
    padding-left: 2vw; }
  #cart .cart-delete {
    text-align: center; }
    @media (max-width: 767.98px) {
      #cart .cart-delete {
        order: 2;
        position: absolute;
        top: 0;
        right: 0; } }
  #cart .cart-delete a {
    background: url(/assets/images/delete.svg) no-repeat center center;
    display: inline-block;
    width: 21px;
    height: 21px; }
  #cart .delete {
    background: url(/assets/images/delete.svg) no-repeat center center;
    border: none;
    width: 21px;
    height: 21px; }
  #cart .cart-image {
    width: 15%; }
    @media (max-width: 767.98px) {
      #cart .cart-image {
        display: none; } }
  #cart .cart-image img {
    width: 6vw; }
  @media (max-width: 767.98px) {
    #cart .cart-item {
      order: 1;
      padding-right: 35px; } }
  #cart .cart-item-name {
    font-weight: bold; }
    @media (max-width: 767.98px) {
      #cart .cart-item-name {
        font-size: 18px; } }
  #cart .cart-quantity {
    width: 25%;
    text-align: center; }
    @media (max-width: 767.98px) {
      #cart .cart-quantity {
        width: 100%;
        order: 3;
        text-align: right; }
        #cart .cart-quantity:before {
          display: block;
          float: left;
          content: attr(data-th);
          font-weight: bold; } }
  #cart .cart-quantity select {
    background-color: #d4d4d4; }
  #cart .cart-quantity-btn {
    display: flex;
    justify-content: center; }
    @media (max-width: 767.98px) {
      #cart .cart-quantity-btn {
        justify-content: flex-end; } }
  #cart .cart-quantity-btn input {
    width: 30px;
    min-width: 30px;
    margin: 0;
    padding: 0;
    text-align: center;
    background-color: #d4d4d4;
    border: hidden; }
  #cart .minus {
    background: url(/assets/images/minus.svg) no-repeat center center;
    width: 21px;
    height: 21px;
    border: 0; }
  #cart .plus {
    background: url(/assets/images/plus.svg) no-repeat center center;
    width: 21px;
    height: 21px;
    border: 0; }
  #cart .cart-price {
    text-align: center; }
    @media (max-width: 767.98px) {
      #cart .cart-price {
        order: 4;
        text-align: right; }
        #cart .cart-price:before {
          display: block;
          float: left;
          content: attr(data-th);
          font-weight: bold; } }
  #cart .cart-sum-text {
    text-align: right;
    padding-right: 4vw;
    font-weight: bold; }
  #cart .cart-sum-price {
    text-align: center;
    font-weight: bold; }
  #cart .button {
    min-width: 135px;
    text-align: center; }
  #cart .button-back img {
    width: 1.8vw;
    position: relative;
    right: 0.5vw; }
    @media (max-width: 767.98px) {
      #cart .button-back img {
        display: none; } }
  #cart .button-back a {
    padding-left: 15px; }
    @media (max-width: 767.98px) {
      #cart .button-back a {
        padding-left: 10px; } }
  #cart .button-back a:hover {
    font-weight: bold; }
  #cart .button-order {
    text-align: right; }
  #cart .cart-flex-content {
    display: flex; }
  #cart .cart-content {
    padding: 0 30px 100px 0;
    flex-grow: 1; }
  #cart .cart-info {
    width: 35vw; }
  #cart .cart-info-text {
    padding: 20px 20px 1vw 20px;
    background-color: #d4d4d4;
    position: fixed;
    width: 33vw; }
    @media (max-width: 991.98px) {
      #cart .cart-info-text {
        position: relative;
        width: auto; } }
  #cart .cart-info-text1 {
    font-weight: bold; }
  #cart .cart-info-text2 {
    line-height: 1.5; }
  #cart .cart-info-text3 {
    padding-right: 5vw; }

#admin {
  min-height: 100vh;
  background: #d4d4d4 url(/assets/images/bio_r_down_corner.svg) no-repeat bottom right;
  background-size: 45vw;
  padding-top: 80px;
  box-sizing: border-box; }
  @media (max-width: 1600px) {
    #admin {
      padding-top: 5vw; } }
  #admin.admin-sign-in .sign-in {
    max-width: 600px;
    margin: 0 auto; }
  #admin.admin-sign-in .sign-in-container {
    margin-left: 5vw;
    margin-right: 5vw;
    margin-top: 50px;
    padding-top: 3vw;
    padding-bottom: 10vw;
    border: 1px solid black;
    border-radius: 4px;
    background: url(/assets/images/plate_thing.svg) no-repeat bottom 10vw left 3vw;
    background-size: 10vw; }
  #admin.admin-sign-in h2 {
    text-align: center;
    padding: 15px 15px 30px 15px; }
  #admin.admin-sign-in .element {
    display: flex;
    justify-content: space-between;
    padding: 10px 15px 10px 15px; }
  #admin.admin-sign-in .element .label {
    flex-grow: 1; }
  #admin.admin-sign-in .element input {
    width: 50%;
    background-color: #d4d4d4;
    border: 1px solid #333333;
    border-radius: 3px; }
  #admin.admin-sign-in .element select {
    margin-left: 5px; }
  #admin.admin-sign-in .sign-in-btn {
    text-align: right;
    padding: 30px 15px 10px 15px; }
  #admin.admin-sign-in button {
    font-size: 13px;
    background-color: #d4d4d4;
    border: 1px solid #333333;
    border-radius: 2px;
    padding: 5px; }
  #admin .title-row {
    padding-top: 104px; }
  #admin .sign-in-btn {
    text-align: center;
    padding: 30px 15px 10px 15px; }
  #admin .buttons-row {
    padding-top: 50px;
    padding-bottom: 94px; }
  #admin .buttons-row-subdiv {
    margin: 15px 0; }
  #admin .table-color {
    background-color: #D4D4D4; }
  #admin button {
    background-color: #d4d4d4;
    border: 1px solid #333333;
    border-radius: 2px;
    font-size: 20px;
    padding: 7px 13px;
    line-height: 24px; }
  #admin .delete {
    background: url(/assets/images/delete.svg) no-repeat center center;
    border: none;
    width: 1.5vw;
    height: 1.5vw; }
  #admin input[type="text"],
  #admin input[type="password"],
  #admin select {
    width: 100%;
    background-color: #d4d4d4;
    border-radius: 2px;
    border: 1px solid #333333;
    font-size: 20px;
    line-height: 24px; }
  #admin input[type="text"],
  #admin input[type="password"] {
    padding: 7px 13px; }
  #admin select {
    padding: 5px 13px; }
  #admin .record-button {
    text-align: right; }
  #admin table, #admin th, #admin td {
    padding: 5px 5px 15px 5px; }
  #admin table {
    width: 100%; }
  #admin th {
    text-align: left; }
  #admin a {
    color: black; }
  #admin .ws-select {
    display: flex;
    flex-grow: 1; }

/* MODAL BOX */
/* The Modal (background) */
.modal {
  display: none;
  /* Hidden by default */
  position: fixed;
  /* Stay in place */
  z-index: 5;
  /* Sit on top */
  left: 0;
  top: 0;
  width: 100%;
  /* Full width */
  height: 100%;
  /* Full height */
  overflow: auto;
  /* Enable scroll if needed */
  background-color: black;
  /* Fallback color */
  background-color: rgba(0, 0, 0, 0.4);
  /* Black w/ opacity */
  /* Modal Content/Box */
  /* The Close Button */ }
  .modal .modal-content {
    background-color: #d4d4d4;
    margin: 150px auto auto auto;
    /* 15% from the top and centered */
    padding: 20px;
    border: 1px solid #888;
    border-radius: 4px;
    width: 90%;
    max-width: 700px;
    margin: 150px auto auto auto; }
    @media (max-width: 1600px) {
      .modal .modal-content {
        margin-top: calc(5vw + 30px); } }
    @media (max-width: 991.98px) {
      .modal .modal-content {
        margin-top: calc(80px + 30px); } }
    @media (max-width: 767.98px) {
      .modal .modal-content {
        margin-top: calc(60px + 30px); } }
  .modal .modal-content p {
    text-align: center;
    padding: 15px 15px 30px 15px; }
  .modal .continue-btn {
    text-align: center;
    padding: 30px 10px 10px 10px; }
  .modal .close {
    color: #aaa;
    float: right;
    font-size: 28px;
    font-weight: bold;
    position: relative;
    top: -16px;
    right: -5px; }
  .modal .close:hover,
  .modal .close:focus {
    color: black;
    text-decoration: none;
    cursor: pointer; }
  .modal .spinner-border {
    display: block;
    margin: auto; }

#order-modal,
#workshop-modal {
  /* Modal Content/Box */ }
  #order-modal .modal-content,
  #workshop-modal .modal-content {
    max-width: 590px; }
    @media (min-width: 1200px) {
      #order-modal .modal-content,
      #workshop-modal .modal-content {
        max-width: 40vw; } }
  #order-modal .error-message,
  #workshop-modal .error-message {
    color: #ff0000;
    display: none; }
    #order-modal .error-message.show,
    #workshop-modal .error-message.show {
      display: block; }
  #order-modal .modal-elements,
  #workshop-modal .modal-elements {
    display: flex;
    justify-content: space-between;
    padding: 10px 5vw 10px 10px; }
    @media (max-width: 575.98px) {
      #order-modal .modal-elements,
      #workshop-modal .modal-elements {
        flex-direction: column;
        padding: 7px 0; } }
  #order-modal .modal-elements input,
  #workshop-modal .modal-elements input {
    width: 70%;
    background-color: #d4d4d4;
    border: 1px solid #333333;
    border-radius: 3px; }
    @media (max-width: 575.98px) {
      #order-modal .modal-elements input,
      #workshop-modal .modal-elements input {
        width: 100%; } }
    #order-modal .modal-elements input input.error,
    #workshop-modal .modal-elements input input.error {
      border-color: #ff0000; }
  #order-modal button,
  #workshop-modal button {
    background-color: #d4d4d4;
    border: 1px solid #333333;
    border-radius: 2px;
    padding: 5px; }
  #order-modal .continue-btn,
  #workshop-modal .continue-btn {
    text-align: center;
    padding: 30px 10px 10px 10px; }
  #order-modal .btn-flex,
  #workshop-modal .btn-flex {
    display: flex;
    justify-content: center; }
  #order-modal .edit-btn,
  #workshop-modal .edit-btn {
    margin-right: 15px; }
  #order-modal .ok-btn,
  #workshop-modal .ok-btn {
    text-align: center; }
  #order-modal #step-2,
  #workshop-modal #step-2 {
    display: none; }

/* MODAL BOX */
#post-modal .modal-content,
#delete-modal .modal-content {
  max-width: 490px; }

#post-modal .modal-elements input,
#delete-modal .modal-elements input {
  width: 70%;
  background-color: #d4d4d4;
  border: 1px solid #333333;
  border-radius: 3px; }

#post-modal .ok-btn,
#delete-modal .ok-btn {
  text-align: center;
  padding: 30px 10px 10px 10px; }
