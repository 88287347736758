#bio {
    padding-top: 100px;
    background: #d4d4d4 url(/assets/images/bio_r_down_corner.svg) no-repeat bottom 0 right 0;
    background-size: 45vw;

    @include media-breakpoint-down(md) {
        padding-top: 35px;
    }
    @include media-breakpoint-down(sm) {
        background-size: 95vw;
        padding-bottom: 30vw;
        padding-top: 30px;
    }

    h2 {
        font-style: normal;
        font-size: 20px;
    }

    ul {
        list-style-type: none;
        font-weight: 400;
        line-height: 2;
    }
   
    .bio-title{
        margin-top: -130px;
        padding-top: 130px;
    }

    .bio-text{
        padding-bottom: 5vw;
    }    

    .bio-break{
        background: url(/assets/images/plate_thing.svg) no-repeat top right 5%;
        background-size: 55%;
    }

    .bio-pic{
        text-align: right;
        @include media-breakpoint-down(md) {
            text-align: center;
        }
        .profile-pic {
            max-height: 25vw;
            @include media-breakpoint-down(md) {
                max-height: none;
                width: 100%;
            }
            @include media-breakpoint-down(sm) {
                width: 80%;
            }            
        }        
    }

}