#products {
    background-color: gray;
    background-image: url(../images/products_line.svg), url(../images/munkaim_hatter_top.svg);
    background-repeat: no-repeat;
    background-position: top, bottom;
    padding-bottom: 14vw;
    padding-left: 0;

    span{
        font-size: .9rem;
        font-weight: 500;
    }

    h1 {
        padding-top: 160px;
        margin-top: -100px;
    }
    
    
    .cd-builder-steps {
        position: relative;
        overflow: hidden;
        text-align: center;
    }
    
    .cd-builder-steps>ul {
        height: 100%;
        overflow: hidden;
        text-align: center;
        padding: 0;
        margin: auto;
        padding-inline-start: 0 !important;
    }
    
    .cd-builder-steps li {
        list-style: none;
        display: inline-block;
        text-align: center;
        width: auto;
    }
    
    .cd-builder-steps .builder-step {
        position: absolute;
        z-index: 1;
        top: 0;
        left: 0;
        width: 100%;
        height: 100%;
        overflow-y: auto;
        overflow-x: hidden;
        -webkit-overflow-scrolling: touch;
        visibility: hidden;
        -webkit-transition: visibility .5s;
        transition: visibility .5s;
    }
    
    .cd-builder-steps .builder-step.active {
        position: relative;
        z-index: 2;
        visibility: visible;
        -webkit-transition: visibility .7s;
        transition: visibility .7s;
    }
    
    .cd-builder-steps .builder-step.first-load {
        /* hide content uploaded via Ajax */
        display: none;
    }
    
    /* -------------------------------- 
      
      Step content - basic style
      
      -------------------------------- */
    .cd-step-content {
    
        opacity: 0;
        /* this is the animation of a section moving right (go back to a prev step) - selection already made */
        -webkit-animation: cd-center-to-right .5s 0s backwards;
        animation: cd-center-to-right .5s 0s backwards;
    }
    
    
    .cd-step-content .options-list>li {
        /* basic style for list of options */
        background: gray;
        margin: .5em;
        border: 10px solid;
        border-image: url(../images/keret.svg) 32 stretch;
        border-image-width: 24px;
        cursor: pointer;
        -webkit-transition: border-image .3s;
        transition: border-image .3s;
    }
    
    .cd-step-content .options-list>li.selected {
        border-color: #d2691e;
    }
    
    .cd-step-content .options-list>li:hover {
        border-image: url(../images/keret_hover.svg) 32 stretch;
        border-image-width: 24px;
    }
    
    
    
    .active .cd-step-content {
        /* this is the animation of the selected step */
        opacity: 1;
        -webkit-transform: translateX(0);
        -ms-transform: translateX(0);
        transform: translateX(0);
        -webkit-animation: cd-right-to-center .5s .2s backwards;
        animation: cd-right-to-center .5s .2s backwards;
    }
    
    .move-left .cd-step-content {
        /* this is the animation of a section moving left - selection already made */
        -webkit-animation: cd-center-to-left .5s 0s backwards;
        animation: cd-center-to-left .5s 0s backwards;
    }
    
    .active.back .cd-step-content {
        /* this is the animation of the selected step - moving down*/
        -webkit-animation: cd-left-to-center .5s .2s backwards;
        animation: cd-left-to-center .5s .2s backwards;
    }
    
    
    
    /* -------------------------------- 
      
      Models Step - style
      
      -------------------------------- */
    .cd-builder-steps .models-list {
        padding-inline-start: inherit;
        text-align: center;
    }
    
    .cd-builder-steps .models-list>li {
        padding: 0 0 1% 0;
    }
    
    .cd-builder-steps .models-list .name {
        display: block;
        margin-top: .2em;
        font-weight: bold;
    }
    
    .cd-builder-steps .models-list img {
        mask-image: url(../images/img_clip.svg);
        mask-repeat: no-repeat;
        mask-position: center;
        object-fit: cover;
        display: block;
        width: 81%;
        margin: 4% auto 3%;
        height: auto;        
    }
    
    .cd-builder-steps .models-list .price {
        display: block;
        font-weight: bold;
    }
    
    
    /* -------------------------------- 
      
      Colors Step - style
      
      -------------------------------- */
    
    
    .cd-product-customizer {
        background: gray;
        margin: .5em;
        padding: .5em;
        text-align: center;
        display: inline-grid;
        border: 10px solid;
        border-image: url(../images/keret.svg) 32 stretch;
        border-image-width: 24px;
        min-height: 320px;
    
    }
    
    .cd-product-customizer li {
        position: relative;
    
        margin: 8px 0px;
    }
    
    .cd-product-customizer .selected a {
        border: 2px solid #d2691e;
        width: 64px;
        height: 64px;
        margin: -5px;
    }
    
    .cd-product-customizer a {
        /* replace text with bg color */
        display: inline-block;
        overflow: hidden;
        text-indent: 100%;
        color: transparent;
        white-space: nowrap;
        width: 54px;
        height: 54px;
        border-radius: 50%;
        border: 2px solid #ffffff;  
        background-size: cover;
        transition: border .3s;
    
    }
    
    .cd-product-customizer ul {
        columns: 3;
        margin-top: 8px;
        padding-inline-start: initial;
        max-width: 230px;
    
    }
    
    
    
    
    
    
    [data-wool-id="fhr"] {
        /* used to give background color to Color options */
        background-image: url(../images/wool_color_id_FHR.jpg);
        background-position: center;
    }
    
    [data-wool-id="fkt"] {
        background-image: url(../images/wool_color_id_FKT.jpg);
        background-position: center;
    }
    
    [data-wool-id="zld"] {
        background-image: url(../images/wool_color_id_ZLD.jpg);
        background-position: center;
    }
    
    [data-wool-id="rel"] {
        background-image: url(../images/wool_color_id_REL.jpg);
        background-position: center;
    }
    
    [data-wool-id="kek"] {
        background-image: url(../images/wool_color_id_KEK.jpg);
        background-position: center;
    }
    
    [data-wool-id="brn"] {
        background-image: url(../images/wool_color_id_BRN.jpg);
        background-position: center;
    }
    
    [data-wool-id="prs"] {
        background-image: url(../images/wool_color_id_PRS.jpg);
        background-position: center;
    }
    
    [data-wool-id="srg"] {
        background-image: url(../images/wool_color_id_SRG.jpg);
        background-position: center;
    }
    
    [data-wool-id="crm"] {
        background-image: url(../images/wool_color_id_CRM.jpg);
        background-position: center;
    }
    
    
    
    
    
    [data-glaze-id="fhr"] {
        /* used to give background color to Color options */
        background-image: url(../images/glaze_color_id_FHR.jpg);
        background-position: center;
    }
    
    [data-glaze-id="fkt"] {
        background-image: url(../images/glaze_color_id_FKT.jpg);
        background-position: center;
    }
    
    [data-glaze-id="zld"] {
        background-image: url(../images/glaze_color_id_ZLD.jpg);
        background-position: center;
    }
    
    [data-glaze-id="lla"] {
        background-image: url(../images/glaze_color_id_LLA.jpg);
        background-position: center;
    }
    
    [data-glaze-id="kek"] {
        background-image: url(../images/glaze_color_id_KEK.jpg);
        background-position: center;
    }
    
    [data-glaze-id="nrc"] {
        background-image: url(../images/glaze_color_id_NRC.jpg);
        background-position: center;
    }
    
    [data-glaze-id="prs"] {
        background-image: url(../images/glaze_color_id_PRS.jpg);
        background-position: center;
    }
    
    [data-glaze-id="srg"] {
        background-image: url(../images/glaze_color_id_SRG.jpg);
        background-position: center;
    }
    
    [data-glaze-id="zlb"] {
        background-image: url(../images/glaze_color_id_ZLB.jpg);
        background-position: center;
    }
    
    [data-pearl-id="srg"] {
        background-image: url(../images/pearl_type_id_SRG.jpg);
        background-position: center;
    }
    
    [data-pearl-id="kek"] {
        background-image: url(../images/pearl_type_id_KEK.jpg);
        background-position: center;
    }
    
    [data-pearl-id="vgy"] {
        background-image: url(../images/pearl_type_id_VGY.jpg);
        background-position: center;
    }
    
    [data-pearl-id="fkt"] {
        background-image: url(../images/pearl_type_id_FKT.jpg);
        background-position: center;
    }
    
    [data-pearl-id="prs"] {
        background-image: url(../images/pearl_type_id_PRS.jpg);
        background-position: center;
    }
    
    [data-pearl-id="atl"] {
        background-image: url(../images/pearl_type_id_ATL.jpg);
        background-position: center;
    }
    
    [data-glass-id="brn"] {
        background-image: url(../images/glass_color_id_BRN.jpg);
        background-position: center;
    }
    
    [data-glass-id="fhr"] {
        background-image: url(../images/glass_color_id_FHR.jpg);
        background-position: center;
    }
    
    [data-glass-id="kek"] {
        background-image: url(../images/glass_color_id_KEK.jpg);
        background-position: center;
    }
    
    [data-glass-id="mlf"] {
        background-image: url(../images/glass_color_id_MLF.jpg);
        background-position: center;
    }
    
    [data-glass-id="vgy"] {
        background-image: url(../images/glass_color_id_VGY.jpg);
        background-position: center;
    }
    
    [data-glass-id="zld"] {
        background-image: url(../images/glass_color_id_ZLD.jpg);
        background-position: center;
    }
    
    
    
    /* -------------------------------- 
      
      Summary Step - style
      
      -------------------------------- */
    .cd-builder-steps .summary-image img {
        mask-image: url(../images/img_clip.svg);
        mask-repeat: no-repeat;
        mask-position: center;
        object-fit: cover;
        display: block;
        margin: 4% auto 3%;
        height: auto;
        width: 100%;
        max-width: 264px;        
    }
    
    .cd-builder-steps .summary-box {
        background-color: gray;
        border: 10px solid;
        border-image: url(../images/keret.svg) 64 stretch;
        border-image-width: 32px;
        padding: 16px;
        margin: 0 10px;
    }
    
    
    .cd-builder-steps .summary-list .product-preview {
        display: block;
        max-width: 700px;
        width: 100%;
        margin-bottom: 2em;
    }
    
    .cd-builder-steps .summary-list h3 {
        font-size: 1.1rem;
        font-weight: bold;
        margin: .4em 0;
    }
    
    .cd-builder-steps .summary-list p {
        color: #fff;
        line-height: 1.6;
    }
    
    .cd-builder-steps .summary-list .summary-color::after {
        clear: both;
        content: "";
        display: block;
    }
    
    .cd-builder-steps .summary-list .color-swatch {
        display: inline-block;
        height: 40px;
        width: 40px;
        border: 2px solid #ffffff;    
        border-radius: 50%;
        background-size: cover;
    }
    
    .cd-builder-steps .summary-list .color-label {
        display: inline-block;
    
        margin-top: 11px;
        margin-left: .5em;
    }
    
    
    .cd-builder-steps .summary-list {
        padding-inline-start: inherit;
        display: inline-grid;
    
    }
    
    .cd-builder-steps .summary-list div {
        align-items: center;
        display: inline-flex;
        height: 54px;
    }
    
    .cd-builder-steps .summary-list span {
    
        margin: 0px 16px;
    }
    
    .cd-builder-steps .summary-list>li {
        margin: 4px;
        padding: 12px;
        border: 1px solid;
        display: inline-block;
        text-align: left;
        border-image: url(../images/gomb.svg) 32 stretch;
        border-image-width: 24px;
        display: flex;
        justify-content: space-between;
        align-items: center;        
    }
    
    .cd-builder-steps .summary-list h2::before {
        margin: 0 auto .6em;
    }
    
    .cd-builder-steps .summary-list h3 {
        text-align: center;
    }
    
    .cd-builder-steps .summary-list .summary-color {
        display: inline-block;
    }
    
    .cd-builder-steps .summary-list .color-swatch {
        height: 50px;
        width: 50px;
        min-height: 50px;
        min-width: 50px;        
    }
    
    
    
    /* -------------------------------- 
      
      Builder top navigation
      
      -------------------------------- */
    .cd-builder-main-nav {
        text-align: center;
        margin-bottom: 16px;
    }
    
    
    
    
    .cd-builder-main-nav li {
        display: inline-block;
    
    }
    
    .cd-builder-main-nav a {
        display: block;
        margin-right: 16px;
        color: black;
        font-weight: bold;
        text-transform: uppercase;
        border-bottom: 2px solid transparent;
        -webkit-transition: color .2s, border-color .2s;
        transition: color .2s, border-color .2s;
    }
    
    .cd-builder-main-nav a:hover {
        color: #d2691e;
        text-decoration: none;
    }
    
    .cd-builder-main-nav .active a {
        border-color: #000;
        color: #000;
    }
    
    .cd-builder-main-nav .active a:hover {
        color: #d2691e;
    }
    
    
    
    
    li.inactive {
        visibility: hidden;
        display: none;
    
    }
    
    li.active {
        visibility: visible;
        display: inline-block;
    
    
    }
    
    .minus {
        background: url(../images/minus.svg) no-repeat center center;
        width: 32px;
        height: 32px;
        border: 0;
        -webkit-transition: background .3s;
        transition: background .3s;
    }
    
    .minus:hover {
        background: url(../images/minus_hover.svg) no-repeat center center;
    }
    
    .plus {
        background: url(../images/plus.svg) no-repeat center center;
        width: 32px;
        height: 32px;
        border: 0;
        -webkit-transition: background .3s;
        transition: background .3s;
    }
    
    .plus:hover {
        background: url(../images/plus_hover.svg) no-repeat center center;
    }

    .cart-quantity-btn{
        display: flex;
        justify-content: center;
        align-items: center;
    }
    
    .cart-quantity-btn input {
        width: 30px;
        min-width: 30px;
        margin: 0;
        padding: 0;
        appearance: none;
        text-align: center;
        background-color: gray;
        border: hidden;
        font-size: 24px;
    }
    
    .button-style {
        margin: 4px;
        padding: 8px 20px;
        border: none;
        text-align: center;
    
        font-size: 24px;
    
        background: url(../images/gomb.svg) no-repeat;
        -webkit-transition: background .3s;
        transition: background .3s;
    
    }
    
    .button-style:hover {
    
        color: white;
        background: url(../images/gomb_hover.svg) no-repeat;
    
    }
    
    
    
    
    
    @-webkit-keyframes cd-right-to-center {
        0% {
            opacity: 0;
            -webkit-transform: translateX(30px);
            transform: translateX(30px);
        }
    
        100% {
            opacity: 1;
            -webkit-transform: translateX(0);
            transform: translateX(0);
        }
    }
    
    @keyframes cd-right-to-center {
        0% {
            opacity: 0;
            -webkit-transform: translateX(30px);
            transform: translateX(30px);
        }
    
        100% {
            opacity: 1;
            -webkit-transform: translateX(0);
            transform: translateX(0);
        }
    }
    
    @-webkit-keyframes cd-left-to-center {
        0% {
            opacity: 0;
            -webkit-transform: translateX(-30px);
            transform: translateX(-30px);
        }
    
        100% {
            opacity: 1;
            -webkit-transform: translateX(0);
            transform: translateX(0);
        }
    }
    
    @keyframes cd-left-to-center {
        0% {
            opacity: 0;
            -webkit-transform: translateX(-30px);
            transform: translateX(-30px);
        }
    
        100% {
            opacity: 1;
            -webkit-transform: translateX(0);
            transform: translateX(0);
        }
    }
    
    @-webkit-keyframes cd-center-to-left {
        0% {
            opacity: 1;
            -webkit-transform: translateX(0);
            transform: translateX(0);
        }
    
        100% {
            opacity: 0;
            -webkit-transform: translateX(-30px);
            transform: translateX(-30px);
        }
    }
    
    @keyframes cd-center-to-left {
        0% {
            opacity: 1;
            -webkit-transform: translateX(0);
            transform: translateX(0);
        }
    
        100% {
            opacity: 0;
            -webkit-transform: translateX(-30px);
            transform: translateX(-30px);
        }
    }
    
    @-webkit-keyframes cd-center-to-right {
        0% {
            opacity: 1;
            -webkit-transform: translateX(0);
            transform: translateX(0);
        }
    
        100% {
            opacity: 0;
            -webkit-transform: translateX(30px);
            transform: translateX(30px);
        }
    }
    
    @keyframes cd-center-to-right {
        0% {
            opacity: 1;
            -webkit-transform: translateX(0);
            transform: translateX(0);
        }
    
        100% {
            opacity: 0;
            -webkit-transform: translateX(30px);
            transform: translateX(30px);
        }
    }

    .open-hd-image{
        width: 20px;
        height: 20px;
        background: url(/assets/images/search-plus-solid.svg) no-repeat 50%;
        background-size: auto 100%;
        display: block;
        position: absolute;
        right: 5px;
        bottom: 5px;
        transition: all .2s ease-in-out;
        transform: scale(1);
        &:hover{
            transform: scale(1.2);
        }
    }

    /* Tooltip container */
    .tooltip {
        position: relative;
        display: inline-block;
    }
    
    /* Tooltip text */
    .tooltip .tooltiptext {
        visibility: hidden;
        width: 120px;
        background-color: black;
        color: #fff;
        text-align: center;
        padding: 5px 0;
        border-radius: 6px;
        left: 110%;
        top: 15%;
        /* Position the tooltip text - see examples below! */
        position: absolute;
        z-index: 1;
    }
    
    /* Show the tooltip text when you mouse over the tooltip container */
    .tooltip:hover .tooltiptext {
        visibility: visible;
    }
    
    .tooltip .tooltiptext::after {
        content: " ";
        position: absolute;
        top: 50%;
        right: 100%; /* To the left of the tooltip */
        margin-top: -5px;
        border-width: 5px;
        border-style: solid;
        border-color: transparent black transparent transparent;
    }
}

