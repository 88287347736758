#main-carousel {

    @media (orientation: portrait) {
        @include media-breakpoint-down(md) {      
            padding-top: $header-md;
        } 
        @include media-breakpoint-down(sm) {      
            padding-top: $header-sm;
        }         
    }

    .item {
        padding-bottom: 100vh;
        background: no-repeat 50%;
        background-size: cover;
        min-height: 800px;

        @include media-breakpoint-down(lg) {
            min-height: 600px;         
        }         

        @include media-breakpoint-down(md) {
            min-height: 500px;         
        } 

        @include media-breakpoint-down(sm) {
            min-height: 300px;         
        }  

        @media (orientation: portrait) {
            padding-bottom: 50%;
            min-height: auto;
        }
    }

    .owl-loaded .owl-drag {
        bottom: 150px;
    }

    .owl-nav {
        display: flex;
        -ms-flex-pack: justify;
        justify-content: space-between;
        margin: 0 50px;
        color: #000;

        @include media-breakpoint-down(md) {
            display: none;       
        }         
    }

    .owl-nav button.owl-next,
    .owl-nav button.owl-prev {
        position: absolute;
        top: 80px;
        bottom: 0;
        margin: auto;
        height: 50px;
        width: 50px;

        @media (max-width: 1600px){
            top: 5vw;  
            height: 3.125vw; 
            width: 3.125vw; 
        }  
     
    }

    .owl-nav button.owl-prev {
        background: url(/assets/images/arrow_left.svg) no-repeat;
        left: 20px;
        &:hover{
            background-image: url(/assets/images/arrow_left_hover.svg);
        }        
    }    

    .owl-nav button.owl-next {
        background: url(/assets/images/arrow_right.svg) no-repeat;
        right: 20px;
        &:hover{
            background-image: url(/assets/images/arrow_right_hover.svg);
        }        
    }

    .owl-dots {
        position: absolute;
        bottom: 30px;
        left: 0;
        right: 0;

        @media (max-width: 1600px){
            bottom: 1.875vw;  
        }

        .owl-dot{
            margin: 0 60px;

            @media (max-width: 1600px){
                margin: 0 3.75vw;  
            }
        } 
    }
}