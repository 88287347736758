#contact {
    min-height: auto;
    background: #bcbcbc url(/assets/images/contact_bg.png) no-repeat 0;
    background-size: 60vw;
    padding: 0 0 9vw 0;

    h1 {
        padding-top: 60px;
    }

    .spoon-graph {
        display: flex;
        margin-bottom: 21vw;

        &:after {
            background: url(/assets/images/spoonfriend.svg) no-repeat center center;
            content: "";
            background-size: contain;
            width: 7.083vw;
            height: 20.83vw;
            position: absolute;
            top: 2.5vw;
            left: 1.5vw;            
        }
    }

    .text-row{
        padding-top: 0.52vw;
        [class^="col"]{
            text-align: right;

            @include media-breakpoint-down(sm) {
                text-align: center;
            }
        }
        .text {
            display: inline-flex;
            flex-flow: column;
        }        
    }

    .text-elements {
        display: flex;
        flex-grow: 1;
        align-items: center;
        padding: 8px;
    }

    .text-elements img {
        height: 32px;
        margin-left: 0;
        align-self: center;
        margin-right: 12px;
    }

    .text-elements h2 {
        align-self: center;
        padding-left: 15px;
    }

    p {
        text-align: center;
        line-height: 1.2;
        font-size: 16px;
    }

    h5 {
        font-size: 1.0416vw;
        margin: 0;

        @include media-breakpoint-down(lg) {
            font-size: 15px;
        }
    }
    a{
        text-decoration: none;
        color: $dark;

        &:hover{
            color: $hover-color;
        }
    }


}



//  img {
// height: 20vw;
// margin-left: auto;
// }