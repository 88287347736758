.block{
    h1{
        @include media-breakpoint-down(md) {
            text-align: center;
        }

        &.title-right{
            text-align: right;
            @include media-breakpoint-down(md) {
                text-align: center;
            }
        }        

        &:after {
            display: block;
            width: calc(100% + 32px);
            height: 6px;
            margin-left: -16px;
            margin-right: -16px;
            content: "";
            background: url(../images/line.svg) no-repeat center center;
            background-size: 100% auto;
           
            @include media-breakpoint-down(sm) {
                background-image: url(../images/mobile_title_line.svg);
                background-size: 220px auto;
            }            
        }


    }
}

@import 'blocks/main-carousel';
@import 'blocks/bio';
@import 'blocks/products';
@import 'blocks/works';
@import 'blocks/workshop';
@import 'blocks/contact';
@import 'blocks/cart';
@import 'blocks/admin';
