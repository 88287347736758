#footer {
    padding-top: 10px;
    background-color: #808080;

    .credits {
        text-align: center;

        & a {
            color: black;
            padding-right: 10px;
            font-size: 15px;

            &:hover {
                color: #d2691e;
            }
        }
    }

    .footer-title,
    .footer-xfb {
        margin-bottom: 0;
        text-align: center;

    }

    .footer-title {
        font-size: 14px;
    }

    .footer-xfb {
        font-size: 12px;

        a {
            color: $dark-color;
            text-decoration: none;

            &:hover {
                color: $hover-color;
            }
        }
    }
}