#order-modal,
#workshop-modal{
    /* Modal Content/Box */
    .modal-content {
        max-width: 590px;

        @include media-breakpoint-up(xl) {
            max-width: 40vw;
        }
    }

    .error-message {
        color: #ff0000;
        display: none;
        &.show {
            display: block;
        }        
    }    

    .modal-elements {
        display: flex;
        justify-content: space-between;
        padding: 10px 5vw 10px 10px;
        @include media-breakpoint-down(xs) {
            flex-direction: column;
            padding: 7px 0;
        }
    }

    .modal-elements input {
        width: 70%;
        background-color: #d4d4d4;
        border: 1px solid #333333;
        border-radius: 3px;

        @include media-breakpoint-down(xs) {
            width: 100%;
        }

        input.error {
            border-color: #ff0000;
        }
    }

    button {
        background-color: #d4d4d4;
        border: 1px solid #333333;
        border-radius: 2px;
        padding: 5px;
    }

    .continue-btn {
        text-align: center;
        padding: 30px 10px 10px 10px;
    }


    .btn-flex {
        display: flex;
        justify-content: center;
    }

    .edit-btn {
        margin-right: 15px;
    }

    .ok-btn {
        text-align: center;
    }

    #step-2 {
        display: none;
    }
}