#admin{
    min-height: 100vh;
    background: #d4d4d4 url(/assets/images/bio_r_down_corner.svg) no-repeat bottom right;
    background-size: 45vw;
    padding-top: 80px;
    box-sizing: border-box;

    @media (max-width: 1600px){
        padding-top: 5vw;  
    }    

    &.admin-sign-in{
        .sign-in{
            max-width: 600px;
            margin: 0 auto;
        }
        
        .sign-in-container{
            margin-left: 5vw;
            margin-right: 5vw;
            margin-top: 50px;
            padding-top: 3vw;
            padding-bottom: 10vw;
            border: 1px solid black;
            border-radius: 4px;
            background: url(/assets/images/plate_thing.svg) no-repeat bottom 10vw left 3vw;
            background-size: 10vw;
        }

        h2{
            text-align: center;
            padding: 15px 15px 30px 15px;
        }

        .element{
            display: flex;
            justify-content: space-between;
            padding: 10px 15px 10px 15px;
        }
        .element .label{
            flex-grow: 1;
        }
        .element input{
            width: 50%;
            background-color: #d4d4d4;
            border:1px solid #333333;
            border-radius: 3px;
        }
        .element select{
            margin-left: 5px;
        }
        .sign-in-btn{
            text-align: right;
            padding: 30px 15px 10px 15px;
        }
        button{
            font-size: 13px;
            background-color: #d4d4d4;
            border: 1px solid #333333;
            border-radius: 2px;
            padding: 5px;
        }

    }

    
    .title-row{
        padding-top: 104px;
    }
    .sign-in-btn{
        text-align: center;
        padding: 30px 15px 10px 15px;
    }
    .buttons-row{
        padding-top: 50px;
        padding-bottom: 94px;
    }

    .buttons-row-subdiv{
        margin: 15px 0;
    }

    .table-color{
        background-color: #D4D4D4;
    }

    button{
        background-color: #d4d4d4;
        border: 1px solid #333333;
        border-radius: 2px;
        font-size: 20px;
        padding: 7px 13px;
        line-height: 24px;        
    }
    .delete{
        background: url(/assets/images/delete.svg) no-repeat center center;
        border: none;
        width: 1.5vw;
        height: 1.5vw;
    }

    input[type="text"],
    input[type="password"],
    select{
        width: 100%;
        background-color: #d4d4d4;
        border-radius: 2px;
        border: 1px solid #333333;
        font-size: 20px;
        line-height: 24px;     
    }

    input[type="text"],
    input[type="password"]{
        padding: 7px 13px;
    }

    select{
        padding: 5px 13px;
    }
    .record-button{
        text-align: right;
    }
    table, th, td {
        padding: 5px 5px 15px 5px;
    }
    table{
        width: 100%;
    }
    th{
        text-align: left;
    }
    a{
        color: black;
    }
    .ws-select{
        display: flex;
        flex-grow: 1;
    }
}