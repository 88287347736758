#workshop {
    height: auto;
    background: gray url(/assets/images/munkaim_hatter_bottom.svg) no-repeat top;
    background-size: 100% auto;
    padding-top: 10vw;

    h1 {
        padding-top: 60px;
    }

    h3 {
        font-size: 24px;
        font-weight: bold;
    }

    h4 {
        font-size: 1.041vw;
        font-weight: bold;
        margin-bottom: 1.406vw;

        @include media-breakpoint-down(lg) {
            font-size: 15px;
        }
    }

    .workshop-title{
        margin-top: -70px;
        padding-top: 70px;
    }
    .ws-type-row {
        margin-top: 1.6927vw;
    }

    .ws-type {
        text-align: center;
    }

    .ws-info {
        margin-bottom: 1.5625vw;
    }

    .ws-pic {

        img {
            width: 100%;
            
        }

    }

    .ws-pic-img {
        width: 100%;
        padding-bottom: 65%;
        background-size: cover;
        background-position: center center;
        background-repeat: no-repeat;
        margin-bottom: 20px;
        mask-image: url(/assets/images/img_clip_rectangle.svg);
        mask-repeat: no-repeat;
        mask-position: center;
        object-fit: cover;
        display: block;
    }

    .ws-dates {
        position: relative;
        padding: 15px;
        display: flex;
        flex-direction: column;

        @media(max-width: 350px){
            padding: 10px;
            [class*="col"]{
                margin-left: 0;
                flex: 0 0 100%;
                max-width: 100%;                
            }
        }

        a {
            text-decoration: none;
            color: black;
        }

        a:hover {
            color: $hover-color;
        }

        h3 {
            margin: 0;
            padding-right: 50px;
            font-size: 20px;
        }

    }
    .workshop_table{
        width: 100%;
        td {
            padding-bottom: 1.145vw;  
            .workshop-item{
                display: flex;
                justify-content: space-around;
                align-items: center;

                span{
                    margin: 0 15px;
                    white-space: nowrap;
                }

                @include media-breakpoint-down(sm) {
                    flex-direction: column;
                    justify-content: center;
                    align-items: flex-start;                    
                }
            }
            &:nth-child(2){
                text-align: right;
                padding-right: 15px;
            }
        }        
    }
    .location {
        display: flex;
        align-items: baseline;
    }
}