/* MODAL BOX */

/* The Modal (background) */
.modal {
    display: none;
    /* Hidden by default */
    position: fixed;
    /* Stay in place */
    z-index: 5;
    /* Sit on top */
    left: 0;
    top: 0;
    width: 100%;
    /* Full width */
    height: 100%;
    /* Full height */
    overflow: auto;
    /* Enable scroll if needed */
    background-color: rgb(0, 0, 0);
    /* Fallback color */
    background-color: rgba(0, 0, 0, 0.4);
    /* Black w/ opacity */

    /* Modal Content/Box */
    .modal-content {
        background-color: #d4d4d4;
        margin: 150px auto auto auto;
        /* 15% from the top and centered */
        padding: 20px;
        border: 1px solid #888;
        border-radius: 4px;
        width: 90%;
        max-width: 700px;

        margin: 150px auto auto auto;
    
        @media (max-width: 1600px){
            margin-top: calc(5vw + 30px);  
        }
    
        @include media-breakpoint-down(md) {
            margin-top: calc(#{$header-md} + 30px);  
        }   
    
        @include media-breakpoint-down(sm) {
            margin-top: calc(#{$header-sm} + 30px);  
        }         
    }

    .modal-content p {
        text-align: center;
        padding: 15px 15px 30px 15px;
    }

    .continue-btn {
        text-align: center;
        padding: 30px 10px 10px 10px;
    }

    /* The Close Button */
    .close {
        color: #aaa;
        float: right;
        font-size: 28px;
        font-weight: bold;
        position: relative;
        top: -16px;
        right: -5px;        
    }

    .close:hover,
    .close:focus {
        color: black;
        text-decoration: none;
        cursor: pointer;
    }

    .spinner-border {
        display: block;
        margin:auto;
    }
}

@import 'modals/forms';
@import 'modals/admin';