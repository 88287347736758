#cart{
    padding-top: 140px;
    min-height: 100vh;
    background-color: $background-color;
    padding-bottom: 15vw;

    @include media-breakpoint-down(sm) {
        padding-bottom: 0;
    }   

    &:after{
        content: "";
        display: block;
        position: fixed;
        background: $background-color url(/assets/images/bio_r_down_corner.svg) no-repeat bottom right;
        background-size: 38vw;
        bottom: 0;
        right: 0;
        width: 100vw;
        height: 20vw;
        z-index: 1;

        @include media-breakpoint-down(sm) {
            height: 26vw;
            background-size: 92vw;
            position: static;
            margin-top: 4vw;
        }   
    }

    .container-fluid{
        position: relative;
        z-index: 2;
    }

    a{
        color: #000000;
        text-decoration: none;
    }

    h1{
        margin: 0;
    }

    input[type=number]::-webkit-inner-spin-button, 
    input[type=number]::-webkit-outer-spin-button { 
      -webkit-appearance: none; 
      margin: 0; 
    }    

    .cart-body{
        position: relative;
        padding-bottom: 10vw;
    }
    table, th, td {
        padding: 15px;
        @include media-breakpoint-down(sm) {
            padding: 5px 0;
        }        
    }
    table{
        width: 100%;
        border-spacing: 0;
        @include media-breakpoint-down(sm) {
            display: flex;
            flex-direction: column;
            padding: 20px 0 0 0;            
        }
    }
    tbody{
        tr{
            @include media-breakpoint-down(sm) {
                display: flex;
                flex-direction: column;
                position: relative;
                margin-bottom: 10px;
                padding-bottom: 10px;
                &:after{
                    position: absolute;
                    bottom: 0;
                    left: 0;
                    right: 0;
                    order: 10;
                    content: '';
                    display: block;
                    width: 50%;
                    height: 2px;
                    margin: 0 auto;
                    background: url(../images/mobile_title_line.svg) no-repeat;  
                    background-size: contain;                  
                }
            }
        }
    }

    tfoot{
        tr{
            @include media-breakpoint-down(sm) {
                display: flex;
                justify-content: center;
                padding-bottom: 10px;

                &:last-child{
                    justify-content: space-between;
                    padding-bottom: 0;
                }
            }
        }

        
    }

    thead{
        @include media-breakpoint-down(sm) {
            display: none;
        }
    }
    th{
        font-weight: 700;
        &:nth-child(3),
        &:nth-child(4){
            text-align: center;
        }
    }
    td {
        vertical-align: middle;
        &:last-child{
            white-space: nowrap;
        }
    }
    .cart-product{
        text-align: left;
        padding-left: 2vw;
    }
    .cart-delete{
        text-align: center;
        @include media-breakpoint-down(sm) {
            order: 2;
            position: absolute;
            top: 0;
            right: 0;
        }             
    }
    .cart-delete a{
        background: url(/assets/images/delete.svg) no-repeat center center;
        display: inline-block;
        width: 21px;
        height: 21px;
    }
    .delete{
        background: url(/assets/images/delete.svg) no-repeat center center;
        border: none;
        width: 21px;
        height: 21px;   
    }
    .cart-image{
        width: 15%;
        @include media-breakpoint-down(sm) {
            display: none;
        }
    }
    .cart-image img{
        width: 6vw;
    }
    .cart-item{
        @include media-breakpoint-down(sm) {
            order: 1;
            padding-right: 35px;
        }
    }
    .cart-item-name{
        font-weight: bold;
        @include media-breakpoint-down(sm) {
            font-size: 18px;
        }
    }
    .cart-quantity{
        width: 25%;
        text-align: center;
        @include media-breakpoint-down(sm) {
            width: 100%;
            order: 3;
            text-align: right;
            &:before{
                display: block;
                float: left;
                content: attr(data-th);
                font-weight: bold;
            }
        }             
    }
    .cart-quantity select{
        background-color: #d4d4d4;
    }
    .cart-quantity-btn{
        display: flex;
        justify-content: center;
        @include media-breakpoint-down(sm) {
            justify-content: flex-end;
        }    
    }
    .cart-quantity-btn input{
        width: 30px;
        min-width: 30px;
        margin: 0;
        padding: 0;
        text-align: center;
        background-color: #d4d4d4;
        border: hidden;
    }
    .minus{
        background: url(/assets/images/minus.svg) no-repeat center center;
        width: 21px;
        height: 21px;
        border: 0;
    }
    .plus{
        background: url(/assets/images/plus.svg)  no-repeat center center;
        width: 21px;
        height: 21px;
        border:0;
    }
    .cart-price{
        text-align: center;
        @include media-breakpoint-down(sm) {
            order: 4;
            text-align: right;
            &:before{
                display: block;
                float: left;
                content: attr(data-th);
                font-weight: bold;
            }            
        }             
    }
    .cart-sum-text{
        text-align: right;
        padding-right: 4vw;
        font-weight: bold;
    }
    .cart-sum-price{
        text-align: center;
        font-weight: bold;
    }
    .button{
        min-width: 135px;
        text-align: center;        
    }
    .button-back img{
        width: 1.8vw;
        position: relative;
        right: 0.5vw;

        @include media-breakpoint-down(sm) {
            display: none;
        }
    }
    .button-back a{
        padding-left: 15px;

        @include media-breakpoint-down(sm) {
            padding-left: 10px;
        }
    }
    .button-back a:hover{
        font-weight: bold;
    }
    .button-order{
        text-align: right;
    }
    .cart-flex-content{
        display: flex;
    
    }
    .cart-content{
        padding: 0 30px 100px 0; 
        flex-grow: 1;
    }
    .cart-info{
        width: 35vw;
    }
    .cart-info-text{
        padding: 20px 20px 1vw 20px;
        // background: url(/assets/images/plate_thing.svg) no-repeat bottom 1.5vw left 3vw;
        // background-size: 8vw;
        background-color: $background-color;
        position: fixed;
        width: 33vw;
        @include media-breakpoint-down(md) {
            position: relative;
            width: auto;
        }
    }
    .cart-info-text1{
        font-weight: bold;
    }
    .cart-info-text2{
        line-height: 1.5;
    }
    .cart-info-text3{
        padding-right: 5vw;
    }
}

