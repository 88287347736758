body {
    margin: 0;
    overflow-x: hidden;
    font-family: 'Balsamiq Sans', sans-serif;
    font-size: 1.0416vw;

    @include media-breakpoint-down(lg) {
        font-size: 15px;
    }

    .noscroll &,
    .swipebox-html &{
        overflow: hidden;
    }
}

*:focus{
	outline: none!important;
}

a {
    text-decoration: none;
}

ul li,
p,
span {
    letter-spacing: 0.55px;
    line-height: 1.5;
}

h1,
p {
    font-style: normal;
    font-weight: 400;
}

h1 {
    font-family: salvation, sans-serif;
    font-size: 1.25vw;
    margin: 0 0 2.291vw 0;

    @include media-breakpoint-down(lg) {
        font-size: 24px;
    }
    @include media-breakpoint-down(sm) {
        font-size: 22px;
    }
}

h2 {
    font-weight: 600;
}

nav {
    ul {
        margin: 0;
        padding: 0;
    }
}

.container-fluid {
    max-width: 81.875vw;

    @include media-breakpoint-down(lg) {
        max-width: 91vw;
    }
}

.box-border{
    border: 10px solid;
    border-image: url(../images/box_border.svg) 32 stretch;
    border-image-width: 24px; 
}

.button{
    border: 10px solid;
    border-image: url(../images/gomb.svg) 21 stretch;
    border-image-width: 24px;    
    background-color: #d4d4d4;
    padding: 5px 10px 5px 10px;
    display: inline-block;

    @include media-breakpoint-down(sm) {
        padding-left: 10px;
    }       
    &:hover{
        //border-image: url(../images/gomb_hover.svg) 21 stretch;
    }
}

// .title-left{
//     &::after{
//         display: block;
//         width: 839px;
//         height: 53px;
//         content: "";
//         background: url(/assets/images/Line2.svg) no-repeat 50%;
//         background-size: contain;
//     }
    
// }

// #contact{
//     display: none;
// }