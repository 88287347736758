$header-md: 80px;
$header-sm: 60px;

#header {

    display: flex;
    justify-content: space-between;
    padding: 0 20px;
    position: fixed;
    z-index: 100;
    height: 80px;
    width: 100%;
    background-color: $header-color;
    margin: 0;
    opacity: 0.95;

    @media (max-width: 1600px){
        height: 5vw;  
    }

    @include media-breakpoint-down(md) {
        height: $header-md;
    }   

    @include media-breakpoint-down(sm) {
        height: $header-sm;
    } 


    .logo{
        display: flex;
        color: $dark-color;
        text-decoration: none;

        .icon {
            height: 50px;
            position: relative;
            top: 20%;
            margin-right: 2.4vw;

            @media (max-width: 1600px){
                height: 3.125vw;  
            }     
            @include media-breakpoint-down(md) {
                height: 50px;
            }        
            @include media-breakpoint-down(sm) {
                display: none;
            }
            @media (max-width: 400px){
                height: 27px;
                top: 28%;
            }                      
        }

        p {
            font-size: 30px;
            margin-top: 22px;
            font-family: salvation, sans-serif;
            font-style: normal;
            font-weight: 400;  

            @media (max-width: 1600px){
                font-size: 1.875vw;  
                margin-top: 1.375vw;
            }        
            
            @include media-breakpoint-down(md) {
                font-size: 38px;
                margin-top: 18px;
            }   
            
            @include media-breakpoint-down(sm) {
                font-size: 24px;
                margin-top: 13.5px;
            }
            @media (max-width: 400px){
                font-size: 20px;
                margin-top: 17.5px; 
            }                            
            @media (max-width: 350px){
                font-size: 17px;
                margin-top: 19.5px; 
            }                            
        }
        
    }

    #main-menu{
        display: flex;
        flex-grow: 1;
        justify-content: space-between;
        background-color: $header-color;  

        @include media-breakpoint-down(md) {
            width: 0;
            //width: 50%;
            height: calc(100vh - #{$header-md});
            //height: 0;
            overflow: hidden;
            position: fixed;
            flex-direction: column;
            top: $header-md;
            left: 0;
            bottom: 0;

            transition: width 1s ease-in-out;
            //transition: height 1s ease-in-out;

            .open-menu &{
                width: 50%;                
                //height: calc(100vh - #{$header-md});
            }            
        }   
    
        @include media-breakpoint-down(sm) {
            top: $header-sm;
            height: calc(100vh - #{$header-sm});  
            //width: 100%;           
            .open-menu &{
                width: 100%;
                //height: calc(100vh - #{$header-sm});  
            }                                      
        }    
        
        .icons{
            @include media-breakpoint-down(md) {
                justify-content: space-between;
                padding: 35px;
            }
            @include media-breakpoint-down(md) {
                padding: 25px;
            }
                        
        }
    }

    .plate-thing{
        flex-grow: 1;
        background: url(../images/plate_thing.svg) no-repeat center center;
        background-size: contain;
        margin-left: 20px;
        margin-right: 20px;
    }

    .links {
        margin: 0 auto;
        
        @include media-breakpoint-down(md) {
            padding-top: 15px;
        }
        ul {
            display: flex;
            height: 100%;
            font-family: salvation, sans-serif;
            font-style: normal;
            font-weight: 400;            
            align-items: center;            
            list-style-type: none;
            text-decoration: none;

            @include media-breakpoint-down(md) {
                flex-direction: column;
            }              

            li {
                padding-right: 25px;

                @media (max-width: 1600px){
                   padding-right: 1.5625vw; 
                }                 

                a {
                    position: relative;
                    top: 5px;
                    height: 100%;
                    text-decoration: none;
                    color: #000;
                    font-size: 1.25vw;

                    @media (max-width: 1600px){
                        top: 0.3125vw;
                        font-size: 1.5vw;  
                    }      
                    
                    @include media-breakpoint-down(md) {
                        display: block;                       
                        font-size: 28px;
                        margin-bottom: 25px;
                    }

                    @include media-breakpoint-down(sm) {
                        
                    }

                    &:hover {
                        color: $hover-color;
                    }
                }
            }
        }
    }

    .icons{
        display: flex;
        align-items: center;

        .icons-item {
            position: relative;
            height: 32px;
            margin-right: 15px;

            @media (max-width: 1600px){
                height: 2vw;
                margin-right: 0.9375vw;
            }

            @include media-breakpoint-down(md) {
                height: 45px;
                width: 45px;
            }      
            @include media-breakpoint-down(sm) {
                height: 43.75px;
                width: 43.75px;
            }                           
        }
    
        .cart-icon {
            position: relative;
            display: inline-block;
            height: 32px;
            width: 40px;
            
            @media (max-width: 1600px){
                height: 2vw;
                width: 2.3vw;
            }

            @include media-breakpoint-down(md) {
                height: 43.75px;
                width: 43.75px;
            }      
            @include media-breakpoint-down(sm) {
                height: 37px;
                width: 37px;
            }             
            
            .dot {
                width: 15px;
                height: 15px;
                border-radius: 100%;
                font-size: 10px;
                color: #fff;
                text-align: center;
                background: #000;
                display: inline-grid;
                position: absolute;
                right: -7px;
                top: -4px;
                align-items: center;
            }        
        }
    }
    .hamburger-icon{
        align-items: center;
        a{
            display: block;
            height: 27px;
            @include media-breakpoint-down(sm) {
                height: 20.25px;
            }              
        }
        img{
            height: 27px;
            width: auto;
            display: block;

            @include media-breakpoint-down(sm) {
                height: 20.25px;
            }
        }
    }
    
    .icons-item{
        min-width: 2vw;
        background-position: center!important;
        
    }
    #facebook-icon{
        background: url(/assets/images/facebook.svg)no-repeat;
        -webkit-transition: background .3s;
            transition: background .3s;
    }
    #facebook-icon:hover{
        background: url(/assets/images/facebook_hover.svg)no-repeat;
    }
    #instagram-icon{
        background: url(/assets/images/instagram.svg)no-repeat;
        -webkit-transition: background .3s;
            transition: background .3s;
    }
    #instagram-icon:hover{
        background: url(/assets/images/instagram_hover.svg)no-repeat;
    }
    #telephone-icon{
        background: url(/assets/images/telefon.svg)no-repeat;
        -webkit-transition: background .3s;
            transition: background .3s;
    }
    #telephone-icon:hover{
        background: url(/assets/images/telefon_hover.svg)no-repeat;
    }
    #email-icon{
        background: url(/assets/images/email_uj.svg)no-repeat;
        -webkit-transition: background .3s;
            transition: background .3s;
    }
    #email-icon:hover{
        background: url(/assets/images/email_uj_hover.svg)no-repeat;
    }
    .cart-icon{
        background: url(/assets/images/cart.svg)no-repeat;
        -webkit-transition: background .3s;
            transition: background .3s;
    }
    .cart-icon:hover{
        background: url(/assets/images/cart_hover.svg)no-repeat;
    }
}