#works {
    position: relative;
    background-color: #bcbcbc;
    margin-top: -1px;

    h1 {
        margin: 0 0 1.145vw 0.691vw;

        @include media-breakpoint-down(sm) {
            margin-top: 20px;
        }

        &:after {
            background-image: url(/assets/images/line2.svg);
        }
    }

    .works-title {
        margin-top: calc(-6.25vw - 200px);
        padding-top: 200px;
    }
    
    #works-link{
        .works-links {
            margin-top: -1.0416vw;
            display: flex;
            justify-content: space-between;
            padding-bottom: 10px;
            background: #BCBCBC;
        
            ul li {
                padding-right: 5px;
            }
        
            a {
                position: relative;
                top: 90%;
                height: 100%;
                text-decoration: none;
                padding: 5px 10px 5px 10px;
                color: #000;
                font-style: normal;
            }
        
            a:hover {
                color: $hover-color;
            }
        
            .active {
                background-color: #808080;
                border: 1px solid #808080;
                border-radius: 4px;
            }
        }   
    }

    .works-links{
        .item {
            display: block;
            position: relative;
            cursor: pointer;
            padding-bottom: 58%;
            background: no-repeat 50%;
            background-size: cover;
            mask-image: url(/assets/images/img_clip_rectangle.svg);
            mask-repeat: no-repeat;
            mask-position: center;
            object-fit: cover;   
            margin: 15px;  
    
            .title {
                position: absolute;
                bottom: 0;
                left: 0;
                right: 0;
                padding: 10px;
                background: rgba(0, 0, 0, .75);
                color: #ffff;
                text-align: center;
            }
        }        
    }

    .galleries{
        padding-bottom: 1px;
    }

    .gallery {
        display: none;
        width: 100%;
        max-width: 800px;
        margin: 0 auto;

        .gallery-items {
            display: flex;
            flex-wrap: wrap;
            margin-left: -10px;
            margin-right: -10px;
        }

        .gallery-item {
            box-sizing: border-box;
            width: 20%;
            padding-left: 10px;
            padding-right: 10px;
            margin-bottom: 20px;

            @include media-breakpoint-down(sm) {
                width: 25%;
            }

            @include media-breakpoint-down(xs) {
                width: 33.333%;
            }
        }

        .gallery-item a {
            display: block;
            width: 100%;
            padding-bottom: 100%;
            background: no-repeat center center / cover;
            mask-image: url(/assets/images/img_clip.svg);
            mask-repeat: no-repeat;
            mask-position: center;
            object-fit: cover;
        }
    }
}

#works-carousel {

    @include media-breakpoint-down(xs) {
        display: none;
    }    

    .item {
        cursor: pointer;
        padding-bottom: 65%;
        background: no-repeat 50%;
        background-size: cover;
        mask-image: url(/assets/images/img_clip_rectangle.svg);
        mask-repeat: no-repeat;
        mask-position: center;
        object-fit: cover;     

        .title {
            position: absolute;
            bottom: 0;
            left: 0;
            right: 0;
            padding: 15px;
            background: rgba(0, 0, 0, .75);
            color: #ffff;
            text-align: center;
        }
    }

    .owl-loaded .owl-drag {
        bottom: 150px;
    }

    .owl-nav {
        display: flex;
        justify-content: space-between;
        color: #000;

        @include media-breakpoint-down(lg) {
            display: none;
        }
    }

    .owl-nav button.owl-next,
    .owl-nav button.owl-prev {
        position: absolute;
        top: 0;
        bottom: 0;
        margin: auto;
        height: 50px;
        width: 50px;
    }

    .owl-nav button.owl-prev {
        left: -86px;
        background: url(/assets/images/arrow_left.svg) no-repeat;
    }

    .owl-nav button.owl-next {
        right: -86px;
        background: url(/assets/images/arrow_right.svg) no-repeat;
    }

    .owl-nav button.owl-prev:hover {
        background: url(/assets/images/arrow_left.svg) no-repeat;
    }

    .owl-nav button.owl-next:hover {
        background: url(/assets/images/arrow_right.svg) no-repeat;
    }

    .owl-dots {
        position: relative;
    }

    .owl-dots .owl-dot {
        position: absolute;
        right: 0;
        left: 0;
        margin: auto;
    }
}

