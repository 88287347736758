/* MODAL BOX */
#post-modal,
#delete-modal{
    .modal-content {    
        max-width: 490px;
    }
    
    .modal-elements input{
        width: 70%;
        background-color: #d4d4d4;
        border: 1px solid #333333;
        border-radius: 3px;
    }
    
    .ok-btn{
        text-align: center;
        padding: 30px 10px 10px 10px;
    }
}
